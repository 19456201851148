import { Fragment, useCallback, useContext, useState } from 'react'
import FadeIn from 'global/fadein'

// mui imports
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import FeedbackIcon from '@mui/icons-material/Feedback'
import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import Modal from '@mui/material/Modal'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'

// compendiumX imports
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'

// Context
import { ErrorContext } from 'context/ErrorContext'

// Functional
import { postShareFeedback } from './FeedbackButton.service'

const FeedbackButton = () => {
  // Module specific //
  const location = window.location.pathname
  const { setErrorMessage } = useContext(ErrorContext)
  const [successMessage, setSuccessMessage] = useState()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [feedbackBody, setFeedbackBody] = useState('')
  const handleSetFeedbackBody = useCallback((text: string) => {
    setFeedbackBody(text)
  }, [])
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setFeedbackBody('')
    setOpen(false)
    setSuccessMessage(undefined)
  }

  const sendFeedback = async () => {
    const fetchPostFeedback = async (): Promise<void> => {
      setLoading(true)
      try {
        const feedback = await postShareFeedback(location, feedbackBody)
        setSuccessMessage(feedback)
      } catch (error: any) {
        setErrorMessage(error)
      }
      setLoading(false)
    }
    fetchPostFeedback()
  }

  return (
    <Fragment>
      <MDButton
        variant="contained"
        startIcon={<FeedbackIcon />}
        color="info"
        sx={{
          position: 'fixed',
          zIndex: 1200,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          bottom: '10rem',
          right: 0,
          transform: 'rotate(-90deg)',
          transformOrigin: '100% 100%',
        }}
        onClick={handleOpen}
      >
        Feedback
      </MDButton>
      <Modal open={open} onClose={handleClose} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
        <Box
          sx={({ borders: { borderRadius }, palette: { white } }: Theme) => ({
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            cursor: 'pointer',
            backgroundColor: white.main,
            borderRadius: borderRadius.md,
            p: 5,
          })}
        >
          {loading && !successMessage ? (
            <FadeIn visible={loading}>
              <Box width="100%" height="250px" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={75} />
                <Box>
                  <MDTypography
                    variant="textMD"
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[600],
                    })}
                  >
                    Loading ...
                  </MDTypography>
                </Box>
              </Box>
            </FadeIn>
          ) : (
            <FadeIn visible={!loading}>
              <Stack sx={{ width: '100%' }}>
                <MDTypography
                  variant="textXL_SB"
                  sx={({ palette: { grey } }: Theme) => ({
                    color: grey[800],
                  })}
                >
                  Leave us feedback or report an issue
                </MDTypography>
                {successMessage ? (
                  <FadeIn>
                    <Box height={'150px'} sx={{ display: 'flex', alignItems: 'center', mt: '-30px' }}>
                      <Icon fontSize="large" color="success" sx={{ mr: 2 }}>
                        check_circle
                      </Icon>
                      <MDTypography
                        variant="textMD"
                        sx={({ palette: { dark } }: Theme) => ({
                          color: dark.main,
                        })}
                      >
                        Thank you for your feedback!
                      </MDTypography>
                    </Box>
                  </FadeIn>
                ) : (
                  <FadeIn>
                    <FormControl variant="standard" sx={{ my: 4, width: '100%' }}>
                      <InputLabel shrink>Message</InputLabel>
                      <MDInput
                        placeholder="Enter your feedback message here . . ."
                        multiline
                        onChange={(e: { target: { value: string } }) => handleSetFeedbackBody(e.target.value)}
                        value={feedbackBody}
                        sx={{ textarea: { height: '150px !important' } }}
                      />
                    </FormControl>
                  </FadeIn>
                )}
              </Stack>
            </FadeIn>
          )}
          <Stack direction={'row-reverse'}>
            <MDButton onClick={() => sendFeedback()} variant="contained" color="secondary" disabled={feedbackBody === '' || loading || successMessage}>
              Send feedback
            </MDButton>
            <MDButton onClick={handleClose}>Close</MDButton>
          </Stack>
        </Box>
      </Modal>
    </Fragment>
  )
}
export default FeedbackButton
