export const getDocumentContent = async (documentId: string): Promise<any> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/document/${documentId}`, {
    credentials: 'include',
    method: 'GET',
  })

  if (response.status === 404) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  if (response.status === 500) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return result.document_content
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}
