import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import logo from 'img/logoSmall.png'
import FadeIn from 'global/fadein'

// @mui material components
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles'

// compendiumX imports
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'

// Context
import { getVerify } from './Verify.service'

const Verify = () => {
  const limboState = window.location.hash === '#verified' || window.location.search.includes('Your+email+has+already+been+confirmed')
  const [verifyLoading, setVerifyLoading] = useState(false)
  const [verifyStatus, setVerifyStatus] = useState()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState(0)
  const [companyName, setCompanyName] = useState('')

  const setVerify = async () => {
    setVerifyLoading(true)
    await getVerify(firstName, lastName, phoneNumber || 0, companyName).then((response: any) => setVerifyStatus(response))
    setVerifyLoading(false)
  }

  return (
    <Container maxWidth={'lg'} fixed>
      <MDBox mt={2} mx={'auto'} sx={{ maxWidth: '360px' }}>
        <FadeIn visible={verifyLoading}>
          <Stack spacing={4} sx={{ alignItems: 'center' }}>
            <CircularProgress size={100} />
            <MDTypography
              variant="h6"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Loading user...
            </MDTypography>
          </Stack>
        </FadeIn>
        <FadeIn visible={!verifyLoading}>
          <Stack sx={{ textAlign: 'center' }}>
            <MDBox>
              <img src={logo} alt="Logo" />
            </MDBox>
            {(verifyStatus === 200 || limboState) && (
              <FadeIn visible={verifyStatus === 200 || limboState}>
                <Stack spacing={4} sx={{ alignItems: 'center' }}>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Your verification request has been forwarded
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    The CompendiumX administrators will contact you when the account will be activated
                  </MDTypography>
                </Stack>
              </FadeIn>
            )}
            {!limboState && (verifyStatus === 400 || verifyStatus === 500) && (
              <FadeIn visible={verifyStatus === 400 || verifyStatus === 500}>
                <Stack spacing={4} sx={{ alignItems: 'center' }}>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Request invalid
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    {verifyStatus}
                  </MDTypography>
                  <RouterLink to="/login">
                    <MDBox
                      mt={8}
                      sx={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                      <MDTypography variant="textSM">Back to log in</MDTypography>
                    </MDBox>
                  </RouterLink>
                </Stack>
              </FadeIn>
            )}
            {!verifyStatus && !limboState && (
              <FadeIn visible={!verifyStatus && !limboState}>
                <Stack>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Please verify your account
                  </MDTypography>

                  <FormControl variant="standard" sx={{ mt: 8 }}>
                    <InputLabel shrink>First name</InputLabel>
                    <MDInput placeholder="Enter your first name" onChange={(e: { target: { value: string } }) => setFirstName(e.target.value)} />
                  </FormControl>
                  <FormControl variant="standard" sx={{ mt: 5 }}>
                    <InputLabel shrink>Last name</InputLabel>
                    <MDInput placeholder="Enter your last name" onChange={(e: { target: { value: string } }) => setLastName(e.target.value)} />
                  </FormControl>
                  <FormControl variant="standard" sx={{ mt: 5 }}>
                    <InputLabel shrink>Phone number</InputLabel>
                    <MDInput placeholder="Enter your phone number" onChange={(e: { target: { value: string } }) => setPhoneNumber(Number(e.target.value))} />
                  </FormControl>
                  <FormControl variant="standard" sx={{ mt: 5, mb: 6 }}>
                    <InputLabel shrink>Company</InputLabel>
                    <MDInput placeholder="Enter your company name" onChange={(e: { target: { value: string } }) => setCompanyName(e.target.value)} />
                  </FormControl>

                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="primary"
                    size="large"
                    disabled={firstName === '' || lastName === '' || companyName === ''}
                    onClick={() => setVerify()}
                  >
                    Verify account
                  </MDButton>
                </Stack>
              </FadeIn>
            )}
          </Stack>
        </FadeIn>
      </MDBox>
    </Container>
  )
}

export default Verify
