// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { grey, text, dark, white } = colors;
const { borderColor, borderWidth } = borders;
const { size } = typography;

// types
type Types = any;

const menuItem: Types = {
  styleOverrides: {
    root: {
      borderBottom: `${borderWidth[1]} solid ${borderColor}`,
      minWidth: pxToRem(160),
      minHeight: "unset",
      padding: `${pxToRem(10)} ${pxToRem(18)}`,
      fontSize: size.sm,
      color: text.main,
      transition: "all 300ms ease, color 300ms ease",

      "&:last-of-type": {
        borderBottom: `${borderWidth[1]} solid ${white.main}`,
      },

      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus":
        {
          backgroundColor: grey[50],
          borderBottom: `${borderWidth[1]} solid ${white.main}`,
          color: dark.main,
        },
    },
  },
};

export default menuItem;
