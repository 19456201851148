import { useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'

// @mui material components
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Container from '@mui/material/Container'
import Icon from '@mui/material/Icon'
import Tabs from '@mui/material/Tabs'

// compendiumX imports
import CXDatePicker from 'components/CXDatePicker/CXDatePicker'
import CXTab from 'components/CXTab'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { Theme } from '@mui/material/styles/createTheme'

// Modules
import CategoryCards from 'modules/CategoryCards/CategoryCards'

const Categories = () => {
  const navigate = useNavigate()
  // Ref
  const collections: {
    label: string
    color: 'primary' | 'secondary' | 'info'
  }[] = [
    { label: 'ITS', color: 'primary' },
    { label: 'Single Rulebook', color: 'secondary' },
    { label: 'Q&A', color: 'info' },
  ]

  const { collectionName } = useParams<{
    collectionName: string
  }>()

  const initialCollection = collectionName ? { label: collectionName } : collections[0]

  // Date Filter
  const [activeDateFilter, setActiveDateFilter] = useState(moment())

  // Categories Tabs
  const [activeCollection, setActiveCollection] = useState(initialCollection)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/collections/${newValue}`)
    const categoryIndex = collections.map((collection) => collection.label).indexOf(newValue)
    setActiveCollection(collections[categoryIndex])
  }

  return (
    <Container maxWidth={'xl'} sx={{ mt: '125px' }}>
      <MDBox mt={4} mb={5}>
        <Link to="/">
          <MDButton color="dark" variant="outlined" size="small" startIcon={<ArrowBackIcon />}>
            Back
          </MDButton>
        </Link>
      </MDBox>
      {/* Date Picker */}
      <MDBox sx={{ position: 'absolute', right: '25px', top: '45px', width: '200px', zIndex: 99 }}>
        <MDTypography
          variant="textSM_M"
          sx={({ palette: { grey } }: Theme) => ({
            color: grey[800],
          })}
        >
          Published date
        </MDTypography>
        <CXDatePicker value={activeDateFilter} onChange={(dateValue) => setActiveDateFilter(dateValue)} small />
      </MDBox>
      {/* Tabs */}
      <MDBox>
        <Tabs value={activeCollection.label} onChange={handleChange}>
          {collections.map((collection, index) => (
            <CXTab key={index} icon={<Icon color={collection.color}>inventory_2</Icon>} iconPosition="start" label={collection.label} value={collection.label} color="primary" />
          ))}
        </Tabs>
      </MDBox>
      <MDBox pt={6} sx={{ backgroundColor: 'grey.50' }}>
        <CategoryCards activeCollection={activeCollection.label} activeDateFilter={activeDateFilter} />
      </MDBox>
    </Container>
  )
}

export default Categories
