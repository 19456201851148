import moment from 'moment'
import { Moment } from 'moment'

// Types
import CategoryCardProps from './CategoryCards.type'

export const getCategoryCardsData = async (collection: string, activeDateFilter?: Moment): Promise<CategoryCardProps[]> => {
  const dateFilterFormatted = moment(activeDateFilter).format('YYYY-MM-DDT00:00:00') + 'Z'
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/entry/v1/library/${collection}/document${activeDateFilter ? `/${dateFilterFormatted}` : ''}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return result
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}
