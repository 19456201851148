import { useCallback, useState } from 'react'
import moment from 'moment'
import 'moment/locale/nl'

// @mui material components
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Box from '@mui/material/Box'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { CXIconDatePicker } from 'components/CXIcon/CXIcon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

// types
interface CXDatePickerProps {
  onChange: (val: any) => void
  value?: any
  small?: boolean
}

function OpenPickerIcon() {
  return <CXIconDatePicker sx={{ height: '16px' }}>search</CXIconDatePicker>
}

export default function CXDatePicker({ onChange, value, small }: CXDatePickerProps): JSX.Element {
  const [datePickerOpen, setDatePickerOpen] = useState(false)
  const handleDatePickerOpen = useCallback(
    (e: any) => {
      setDatePickerOpen(!datePickerOpen)
    },
    [datePickerOpen]
  )

  const onKeyDown = (e: any) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      console.log('testing!')
      onChange(moment(value))
    }
    e.preventDefault()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="nl">
      <ClickAwayListener
        onClickAway={() => {
          setDatePickerOpen(false)
        }}
      >
        <Box>
          <DatePicker
            className={`datepickr${small ? '_small' : ''}`}
            slotProps={{
              inputAdornment: { position: 'start', variant: 'standard' },
              textField: {
                fullWidth: true,
                onClick: handleDatePickerOpen,
                onKeyDown: onKeyDown,
                disabled: true,
                inputProps: { sx: { color: '#344054 !important', WebkitTextFillColor: '#344054 !important' } },
              },
            }}
            slots={{ openPickerIcon: OpenPickerIcon }}
            defaultValue={moment(value)}
            onChange={(newValue) => {
              onChange(moment(newValue).format('YYYY-MM-DDT00:00:00') + 'Z')
            }}
            open={datePickerOpen}
            closeOnSelect
          />
        </Box>
      </ClickAwayListener>
    </LocalizationProvider>
  )
}
