/* eslint-disable array-callback-return */
import { DocumentTocProps } from './TocPanel.type'

export const truncateToc = (string: string) => {
  return string.length > 17 ? string.slice(0, 17 - 1) + '...' : string
}

export const getChangedNodes = (nodes?: DocumentTocProps[]): { nodes?: DocumentTocProps[] } => {
  let totalChanged = 0
  const mappedNodes = nodes
    ?.filter((node) => node.is_changed)
    .map((node) => {
      return {
        ...node,
        x_child_node: node?.x_child_node
          ?.filter((a) => a.is_changed)
          ?.map((a: DocumentTocProps) => {
            totalChanged = totalChanged + 1
            return {
              ...a,
              x_child_node: a?.x_child_node
                ?.filter((b) => b.is_changed)
                ?.map((b) => {
                  totalChanged = totalChanged + 1
                  return {
                    ...b,
                    x_child_node: b?.x_child_node
                      ?.filter((c: DocumentTocProps) => c.is_changed)
                      ?.map((c: DocumentTocProps) => {
                        totalChanged = totalChanged + 1
                        return {
                          ...c,
                          x_child_node: c?.x_child_node
                            ?.filter((d: DocumentTocProps) => d.is_changed)
                            .map((d) => {
                              totalChanged = totalChanged + 1
                              return {
                                ...d,
                                x_child_node: d?.x_child_node
                                  ?.filter((d: DocumentTocProps) => d.is_changed)
                                  ?.map((e: DocumentTocProps) => {
                                    totalChanged = totalChanged + 1
                                    return {
                                      ...e,
                                      x_child_node: e?.x_child_node
                                        ?.filter((f: DocumentTocProps) => f.is_changed)
                                        .map((f) => {
                                          totalChanged = totalChanged + 1
                                          return {
                                            ...f,
                                            x_child_node: f?.x_child_node
                                              ?.filter((g: DocumentTocProps) => g.is_changed)
                                              .map((g: DocumentTocProps) => {
                                                totalChanged = totalChanged + 1
                                                return { ...g, x_child_node: g?.x_child_node }
                                              }),
                                          }
                                        }),
                                    }
                                  }),
                              }
                            }),
                        }
                      }),
                  }
                }),
            }
          }),
      }
    })
  return { nodes: mappedNodes }
}
export const getTotalChangedNodes = (nodes: DocumentTocProps[] = []): number => {
  let totalChanged = 0

  function traverse(node: DocumentTocProps): void {
    if (node.is_changed) {
      totalChanged += 1
    }
    if (node.x_child_node && Array.isArray(node.x_child_node)) {
      node.x_child_node.forEach(traverse)
    }
  }

  nodes.forEach(traverse)

  return totalChanged
}
