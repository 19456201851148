import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import FadeIn from 'global/fadein'
import { Moment } from 'moment'

// @mui material components
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/system/Stack'
import { Theme } from '@mui/material/styles/createTheme'
import Tooltip from '@mui/material/Tooltip'

// compendiumX imports
import { CXIconExcel, CXIconFile } from 'components/CXIcon/CXIcon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Functional
import { getCategoryCardsData } from './CategoryCards.service'

// Types
import CategoryCardProps from './CategoryCards.type'

// Context
import { ErrorContext } from 'context/ErrorContext'

interface Props {
  activeCollection: string
  activeDateFilter: Moment
}

const CategoryCards: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const { setErrorMessage } = useContext(ErrorContext)

  const { activeCollection, activeDateFilter } = props
  //Module specific
  const [loading, setLoading] = useState(false)
  //Data
  const [categoryCardsData, setCategoryCardsData] = useState<CategoryCardProps[]>([])

  const truncateDocumentName = (string: string) => {
    return string?.length > 35 ? string.slice(0, 35 - 1) + '...' : string
  }

  const truncateLongName = (string: string) => {
    return string?.length > 65 ? string.slice(0, 65 - 1) + '...' : string
  }

  useEffect(() => {
    const fetchCategoryCards = async (): Promise<void> => {
      setLoading(true)
      try {
        setCategoryCardsData(await getCategoryCardsData(activeCollection, activeDateFilter))
      } catch (error: any) {
        setErrorMessage(error)
      }
      setLoading(false)
    }
    fetchCategoryCards()
    // eslint-disable-next-line
  }, [activeCollection, activeDateFilter])

  const goToLink = async (categoryCardItem: CategoryCardProps) => {
    navigate(`/article/${categoryCardItem.document_id}`)
  }

  const categoryCardTemplate = (categoryCardItem: CategoryCardProps, index: number) => {
    return (
      <Grid item xs={12} md={6} lg={1} key={index}>
        <Tooltip
          title={
            <Stack direction="column">
              <MDBox>
                <b>{categoryCardItem.document_name}</b>
              </MDBox>
              {categoryCardItem.longname}
            </Stack>
          }
          placement="top"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                background: 'white',
                display: categoryCardItem.longname?.length > 65 || categoryCardItem.document_name?.length > 35 ? 'block' : 'none',
              },
            },
          }}
        >
          <MDBox
            sx={({ borders, boxShadows }: Theme) => ({
              background: 'white',
              borderRadius: borders.borderRadius,
              cursor: 'pointer',
              transition: 'box-shadow 0.3s ease-out',
              '&:hover': {
                boxShadow: boxShadows.sm,
              },
            })}
            onClick={() => goToLink(categoryCardItem)}
          >
            <MDBox
              p={6}
              height={'120px'}
              sx={({ borders }: Theme) => ({
                borderRadius: borders.borderRadius,
                border: `${borders.borderWidth[1]} solid ${borders.borderColor}}`,
              })}
            >
              <Stack direction={'row'}>
                <MDBox
                  sx={{
                    minWidth: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    backgroundColor: categoryCardItem.type === 'File' ? 'info.main' : 'success.main',
                  }}
                  color="white"
                >
                  {categoryCardItem.type === 'File' ? (
                    <CXIconFile color="inherit" sx={{ width: '15', height: '15' }} />
                  ) : (
                    <CXIconExcel color="inherit" sx={{ width: '15', height: '15' }} />
                  )}
                </MDBox>
                <MDBox ml={3} sx={{ display: 'flex', flexDirection: 'column' }}>
                  <MDTypography
                    variant="textSM_M"
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[800],
                    })}
                  >
                    {truncateDocumentName(categoryCardItem.document_name)}
                  </MDTypography>
                  <MDTypography
                    variant="textXS"
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    {truncateLongName(categoryCardItem.longname)}
                  </MDTypography>
                </MDBox>
              </Stack>
            </MDBox>
          </MDBox>
        </Tooltip>
      </Grid>
    )
  }

  const groupedCategoryCards = _(categoryCardsData)
    .groupBy((item) => item.group)
    // .sortBy('item.group')
    .map((value, key) => ({ name: key, cards: value }))
    .reverse()
    .value()

  return (
    <MDBox>
      {loading && (
        <FadeIn visible={loading}>
          <Stack spacing={4} sx={{ alignItems: 'center' }}>
            <CircularProgress size={100} />
            <MDTypography
              variant="h6"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Loading user...
            </MDTypography>
          </Stack>
        </FadeIn>
      )}
      {!loading && (
        <FadeIn>
          {groupedCategoryCards.map((category, index) => (
            <>
              <MDBox mb={2} mx={4}>
                <MDTypography
                  variant="textLG_M"
                  sx={({ palette: { grey } }: Theme) => ({
                    color: grey[900],
                  })}
                >
                  {category.name}
                </MDTypography>
              </MDBox>
              <Grid container spacing={3} columns={5} px={2}>
                {category.cards.map((card, index) => categoryCardTemplate(card, index))}
              </Grid>
              {index !== groupedCategoryCards.length - 1 ? <Divider sx={{ mt: 5 }} /> : null}
            </>
          ))}
        </FadeIn>
      )}
    </MDBox>
  )
}

export default CategoryCards
