// @ts-ignore
import Cookies from 'js-cookie'

export const getVerify = async (firstName: string, lastName: string, mobileNumber: number, companyName: string) => {
  const accountInfo = {
    first_name: firstName,
    last_name: lastName,
    mobile_number: mobileNumber,
    organization: companyName,
  }
  const cookies = Cookies.get()
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/userdetails`, {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({ 'Content-Type': 'application/json', 'X-CSRF-Token': cookies['XSRF-TOKEN'] }),
      body: JSON.stringify(accountInfo),
    })
    const result = await response.json()
    if (response.ok) {
      return response.status
    }
    if (!response.ok) await Promise.reject(result)
    return response
  } catch (error) {
    return error
  }
}
