import ReactDOM from 'react-dom/client'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'assets/theme'
import './index.css'

// context
import { UserProvider } from 'context/UserContext'
import { GeneralProvider } from 'context/GeneralContext'
import { ErrorProvider } from 'context/ErrorContext'

window.name = "CompX"

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
})
appInsights.loadAppInsights()
root.render(
  <BrowserRouter>
    <GeneralProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </ErrorProvider>
      </ThemeProvider>
    </GeneralProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
