import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// types
type Types = any;

const { secondary } = colors;
const { size, fontWeightMedium } = typography;

const link: Types = {
  defaultProps: {
    underline: "none",
    color: secondary.main,
    cursor: "pointer",
    fontSize: size.sm,
    fontWeight: fontWeightMedium,
  },
};

export default link;
