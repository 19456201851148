// Material Dashboard 2 PRO React TS Base Styles
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { size, fontWeightMedium } = typography;
const { borderRadius } = borders;
const { grey, primary, white } = colors;

// types
type Types = any;

const tab: Types = {
  styleOverrides: {
    root: {
      backgroundColor: grey[200],
      alignItems: "center",
      flexDirection: "row",
      textAlign: "center",
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: size.sm,
      fontWeight: fontWeightMedium,
      textTransform: "none",
      lineHeight: "inherit",
      padding: `${pxToRem(12)} ${pxToRem(20)}`,
      borderRadius: `${borderRadius.lg} ${borderRadius.lg} 0 0`,
      opacity: "1 !important",
      border: `1px solid ${grey[400]}`,
      borderBottom: "none",
      color: primary.focus,

      ".MuiIcon-root": {
        fontSize: `${size.xl} !important`,
      },

      "&.Mui-selected": {
        backgroundColor: primary.main,
        color: white.main,

        ".MuiIcon-root": {
          color: white.main,
        },
      },

      "& .material-icons, .material-icons-round": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },

      "& svg": {
        marginBottom: "0 !important",
        marginRight: pxToRem(6),
      },
    },

    labelIcon: {
      // paddingTop: pxToRem(4),
    },
  },
};

export default tab;
