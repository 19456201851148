import { useContext } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'

// @mui material components
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'

// compendiumX imports
import FeedbackButton from 'modules/FeedbackButton/FeedbackButton'
import TopBar from 'modules/TopBar/TopBar'

// screens
import ArticleViewer from 'modules/ArticleView/ArticleViewer'
import Categories from 'screens/Categories/Categories'
import ChangePassword from 'screens/ChangePassword/ChangePassword'
import ConfirmReset from 'screens/ConfirmReset/ConfirmReset'
import Dashboard from 'screens/Dashboard/Dashboard'
import Help from 'screens/Help/Help'
import Login from 'screens/Login/Login'
import Profile from 'screens/Profile/Profile'
import Reset from 'screens/Reset/Reset'
import Search from 'screens/Search/Search'
import Signup from 'screens/Singup/Signup'
import Verify from 'screens/Verify/Verify'
import Unauthorized from 'screens/Unauthorized/Unauthorized'

// Alt widget iframe
import AltEntryLogin from 'screens/AltEntry/AltEntryLogin'
import AltEntryWidget from 'screens/AltEntry/AltEntryWidget'

// Context
import { ErrorContext } from 'context/ErrorContext'
import { SearchProvider } from 'context/SearchContext'

function App() {
  let location = useLocation()
  const { errorMessage, errorOpen, setErrorOpen } = useContext(ErrorContext)

  return (
    <SearchProvider>
      {location.pathname !== '/login' &&
        location.pathname !== '/signup' &&
        location.pathname !== '/reset' &&
        location.pathname !== '/verify' &&
        location.pathname !== '/changePassword' &&
        location.pathname !== '/unauthorized' &&
        location.pathname !== '/widget/login' &&
        !location.pathname.startsWith('/confirm_reset') && <TopBar />}
      {location.pathname !== '/login' &&
        location.pathname !== '/signup' &&
        location.pathname !== '/reset' &&
        location.pathname !== '/verify' &&
        location.pathname !== '/changePassword' &&
        location.pathname !== '/unauthorized' &&
        location.pathname !== '/widget/login' &&
        !location.pathname.startsWith('/confirm_reset') && <FeedbackButton />}
      {location.pathname.startsWith('/widget/article') && <TopBar profileOnly />}
      {errorMessage && location.pathname !== '/login' && (
        <Fade in={errorOpen}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorOpen(false)
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ position: 'fixed', top: '70px', width: '100%', zIndex: 999, left: 0 }}
          >
            {errorMessage}
          </Alert>
        </Fade>
      )}
      <Routes>
        {/* Default routes */}
        <Route path="/" element={<Dashboard />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        {/* Authentication routes */}
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="reset" element={<Reset />} />
        <Route path="profile" element={<Profile />} />
        <Route path="changePassword" element={<ChangePassword />} />
        <Route path="confirm_reset" element={<ConfirmReset />} />
        <Route path="verify" element={<Verify />} />
        {/* User routes */}
        <Route path="help" element={<Help />} />
        {/* Content routes */}
        <Route path="search" element={<Search />} />
        <Route path="collections/" element={<Categories />} />
        <Route path="collections/:collectionName" element={<Categories />} />
        <Route path="article">
          <Route path=":documentId" element={<ArticleViewer />} />
          <Route path=":documentId/:article_anchor" element={<ArticleViewer />} />
        </Route>
        {/* Exotic routes */}
        {/* Temporary solution for widget iframe */}
        <Route path="widget/login" element={<AltEntryLogin />} />
        <Route path="widget/article_by_datapoints" element={<AltEntryWidget />} />
      </Routes>
    </SearchProvider>
  )
}

export default App
