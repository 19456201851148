// @ts-ignore
import Cookies from 'js-cookie'

//Funtional
import { getSearchQuery } from 'global/getSearchQuery'

// Types
import SearchResultItemProps from './SearchResults.type'

export const getSearchResultsData = async (
  searchTerm: string,
  searchActivePage: number,
  searchFilters?: {
    relatedTaxonomyCodes?: string
    relatedRows?: string
    relatedColumns?: string
    relatedTemplateCodes?: string
  },
  searchSorter?: string,
  filterDocuments?: any[],
  filterDocumentCollection?: any[],
  filterDate?: any
): Promise<SearchResultItemProps[]> => {
  const activePage = searchActivePage === 0 ? 0 : (searchActivePage - 1) * 10
  const searchResultsQuery = getSearchQuery(searchFilters, filterDocuments, filterDocumentCollection, filterDate) || ''

  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/search/?search=${searchTerm}${searchResultsQuery}&orderby=${searchSorter} desc&skip=${activePage}&top=10`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return result
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}

export const getSearchResultsCount = async (
  searchTerm: string,
  searchFilters?: {
    relatedTaxonomyCodes?: string
    relatedRows?: string
    relatedColumns?: string
    relatedTemplateCodes?: string
  },
  filterDocuments?: any[],
  filterDocumentCollection?: any[],
  filterDate?: any
): Promise<number> => {
  const searchResultsQuery = getSearchQuery(searchFilters, filterDocuments, filterDocumentCollection, filterDate) || ''
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/search/count?search=${searchTerm}${searchResultsQuery}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return result.count
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return 0
}

export const getAiSuggestion = async (
  searchTerm: string,
  searchFilters?: {
    relatedTaxonomyCodes?: string
    relatedRows?: string
    relatedColumns?: string
    relatedTemplateCodes?: string
  },
  filterDocuments?: any[],
  filterDocumentCollection?: any[],
  filterDate?: any
): Promise<SearchResultItemProps> => {
  const body = {
    search: searchTerm,
    searchFilters,
    filterDocuments,
    filterDocumentCollection,
    filterDate,
  }
  const cookies = Cookies.get()
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/complychat/answer`, {
    credentials: 'include',
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'X-CSRF-Token': cookies['XSRF-TOKEN'],
    }),
    body: JSON.stringify(body),
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return result
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}
