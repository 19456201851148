import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "img/logoSmall.png";
import FadeIn from "global/fadein";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Icon from "@mui/material/Icon";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { Theme } from "@mui/material/styles";

// compendiumX imports
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Functional
import { getConfirmReset } from "./ConfirmReset.service";

const ConfirmReset = () => {
  // Params
  const queryParams = new URLSearchParams(window.location.search);
  const tokenCode = queryParams.get("token");

  const [resetLoading, setResetLoading] = useState(false);
  const [resetStatus, setResetStatus] = useState();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const setResetConfirmation = async () => {
    setResetLoading(true);
    await getConfirmReset(tokenCode || "", password).then((response) =>
      setResetStatus(response.meta.code)
    );
    setResetLoading(false);
  };

  return (
    <Container maxWidth={"lg"} fixed>
      <MDBox mt={2} mx={"auto"} sx={{ maxWidth: "360px" }}>
        <FadeIn visible={resetLoading}>
          <Stack spacing={4} sx={{ alignItems: "center" }}>
            <CircularProgress size={100} />
            <MDTypography
              variant="h6"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Loading reset...
            </MDTypography>
          </Stack>
        </FadeIn>
        <FadeIn visible={!resetLoading}>
          <Stack sx={{ textAlign: "center" }}>
            <MDBox>
              <img src={logo} alt="Logo" />
            </MDBox>
            {resetStatus === 200 && (
              <FadeIn visible={resetStatus === 200}>
                <Stack spacing={4} sx={{ alignItems: "center" }}>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    New password created
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    Your password has been successfully reset.
                  </MDTypography>
                  {/* <Link to="/login">
                    <MDBox
                      mt={8}
                      sx={{
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                      <MDTypography variant="textSM">
                        Back to log in
                      </MDTypography>
                    </MDBox>
                  </Link> */}
                  <MDBox mt={8}>
                    <MDTypography variant="textMD_SB">
                      It is best to close all browser sessions and log in again.
                    </MDTypography>
                  </MDBox>
                </Stack>
              </FadeIn>
            )}
            {resetStatus === 400 && (
              <FadeIn visible={resetStatus === 400}>
                <Stack spacing={4} sx={{ alignItems: "center" }}>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Request invalid
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    Something went wrong
                  </MDTypography>
                  <Link to="/login">
                    <MDBox
                      mt={8}
                      sx={{
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                      <MDTypography variant="textSM">
                        Back to log in
                      </MDTypography>
                    </MDBox>
                  </Link>
                </Stack>
              </FadeIn>
            )}
            {!resetStatus && (
              <FadeIn visible={!resetStatus}>
                <Stack>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Create a new password
                  </MDTypography>
                  <FormControl variant="standard" sx={{ mt: 5, mb: 5 }}>
                    <InputLabel shrink htmlFor="password-input">
                      Password
                    </InputLabel>
                    <MDInput
                      placeholder="New password"
                      type="password"
                      onChange={(e: { target: { value: string } }) =>
                        setPassword(e.target.value)
                      }
                    />
                    <MDBox sx={{ textAlign: "start" }}>
                      <MDTypography
                        variant="textSM_M"
                        sx={{ color: "grey.500" }}
                      >
                        Must be at least 8 characters.
                      </MDTypography>
                    </MDBox>
                  </FormControl>
                  <FormControl variant="standard" sx={{ mb: 6 }}>
                    <InputLabel shrink htmlFor="confirmpassword-input">
                      Re-enter password
                    </InputLabel>
                    <MDInput
                      placeholder="Re-enter password"
                      type="password"
                      onChange={(e: { target: { value: string } }) =>
                        setPasswordConfirmation(e.target.value)
                      }
                    />
                  </FormControl>
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="primary"
                    size="large"
                    disabled={
                      password === "" || password !== passwordConfirmation
                    }
                    onClick={() => setResetConfirmation()}
                  >
                    Create new password
                  </MDButton>
                  <Link to="/login">
                    <MDBox
                      mt={8}
                      sx={{
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                      <MDTypography variant="textSM">
                        Back to log in
                      </MDTypography>
                    </MDBox>
                  </Link>
                </Stack>
              </FadeIn>
            )}
          </Stack>
        </FadeIn>
      </MDBox>
    </Container>
  );
};

export default ConfirmReset;
