import { useEffect, useState } from "react";
import FadeIn from "global/fadein";

// @mui material components
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/system/Stack";
import { Theme } from "@mui/material/styles/createTheme";

// compendiumX imports
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Functional
import { getHelpData } from "./Help.service";

// Types
import { HelpItemProps } from "./Help.type";

const Help = () => {
  //Module specific
  const [loading, setLoading] = useState(false);
  const [itemOpen, setItemOpen] = useState<number | undefined>();
  //Data
  const [helpData, setHelpData] = useState<HelpItemProps[]>([]);

  useEffect(() => {
    async function fetchHelpData() {
      setLoading(true);
      await getHelpData().then((response) => setHelpData(response));
      setLoading(false);
    }
    fetchHelpData();
  }, []);

  const createMarkup = (data: any) => {
    return { __html: data };
  };

  const helpItemTemplate = (item: HelpItemProps, index: number) => {
    return (
      <MDBox width="768px">
        <MDBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MDTypography variant="textLG_M">{item.question}</MDTypography>
          {itemOpen !== index ? (
            <AddCircleOutlineIcon
              onClick={() => setItemOpen(index)}
              sx={{ cursor: "pointer" }}
            />
          ) : (
            <RemoveCircleOutlineIcon
              onClick={() => setItemOpen(undefined)}
              sx={{ cursor: "pointer" }}
            />
          )}
        </MDBox>
        {itemOpen === index && (
          <FadeIn>
            <MDBox sx={{ display: "block" }} mt={2}>
              {item.answer.map((answerItem) => (
                <MDBox my={2}>
                  <MDTypography
                    variant="textMD"
                    sx={{ color: "#667085" }}
                    dangerouslySetInnerHTML={createMarkup(answerItem)}
                  />
                </MDBox>
              ))}
            </MDBox>
          </FadeIn>
        )}
        <Divider sx={{ mt: 8 }} />
      </MDBox>
    );
  };

  return (
    <Container maxWidth={"lg"} fixed sx={{ mt: 10 }}>
      <MDBox>
        {loading && (
          <FadeIn visible={loading}>
            <Stack spacing={4} sx={{ alignItems: "center" }}>
              <CircularProgress size={100} />
              <MDTypography
                variant="h6"
                sx={({ palette: { grey } }: Theme) => ({
                  color: grey[500],
                })}
              >
                Loading content...
              </MDTypography>
            </Stack>
          </FadeIn>
        )}
        {!loading && (
          <FadeIn>
            <Stack>
              <MDBox
                sx={{
                  textAlign: "center",
                }}
              >
                <MDTypography variant="textMD_SB" sx={{ color: "#7F56D9" }}>
                  FAQs
                </MDTypography>
                <MDBox mt={2}>
                  <MDTypography variant="displaySM">
                    We’re here to help
                  </MDTypography>
                </MDBox>
                <MDBox mt={6}>
                  <MDTypography variant="textXL" sx={{ color: "#667085" }}>
                    Have questions? We’re here to help.
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox
                mt={10}
                sx={{
                  textAlign: "center",
                }}
              >
                <MDTypography variant="displaySM_M">
                  Frequently asked questions
                </MDTypography>
                <MDBox mt={5}>
                  <MDTypography variant="textXL" sx={{ color: "#667085" }}>
                    Everything you need to know about the product and billing.
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mt={9} mr="auto" ml="auto">
                {helpData.map((helpItem, i) => helpItemTemplate(helpItem, i))}
              </MDBox>
              <MDBox
                mt={9}
                sx={{
                  textAlign: "center",
                }}
              >
                <MDTypography variant="textSM">
                  Please contact
                  <Link href={`mailto:support@compendium-x.com`} ml={1}>
                    support@compendium-x.com
                  </Link>
                </MDTypography>
                <MDTypography variant="textSM" ml={1}>
                  for any further questions
                </MDTypography>
              </MDBox>
            </Stack>
          </FadeIn>
        )}
      </MDBox>
    </Container>
  );
};

export default Help;
