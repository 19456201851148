import { useCallback, useContext, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import FadeIn from 'global/fadein'

// @mui material components
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'

// Modules
import DocumentScreen from './DocumentScreen/DocumentScreen'
import DocumentInfoPanel from './DocumentInfoPanel/DocumentInfoPanel'
import MetadataPanel from './MetadataPanel/MetadataPanel'
import TocPanel from './TocPanel/TocPanel'

// Context
import { UserContext } from 'context/UserContext'

const ArticleViewer = () => {
  // User information
  const { userRoles, adminPanel } = useContext(UserContext)
  const accessToc = userRoles && userRoles?.indexOf('table_of_content') > -1

  // Params
  const { documentId, article_anchor } = useParams<{
    documentId: string
    article_anchor: string
  }>()
  const [stateAnchor, setStateAnchor] = useState(article_anchor)
  const handleSetAnchor = useCallback((anchor: any): void => {
    setStateAnchor(anchor)
  }, [])
  const [documentScrolled, setDocumentScrolled] = useState(false)
  const handleSetDocumentScrolled = useCallback((scrolled: boolean) => {
    setDocumentScrolled(scrolled)
  }, [])
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const revisionsOpenForced = Boolean(params.get('revisionsOpen'))

  // View
  const [tocPanelActive, setTocPanelActive] = useState(revisionsOpenForced)
  const handleSetTocPanelActive = useCallback((bool: boolean) => {
    setTocPanelActive(bool)
  }, [])
  const [metadataPanelActive, setMetadataPanelActive] = useState(true)
  const handleSetMetadataPanelActive = useCallback((bool: boolean) => {
    setMetadataPanelActive(bool)
  }, [])
  const [revisionsTabEnforced, setRevisionsTabEnforced] = useState(revisionsOpenForced)
  const handleSetRevisionsTabEnforced = useCallback((bool: boolean) => {
    setRevisionsTabEnforced(bool)
  }, [])

  const getWidth = () => {
    if (tocPanelActive && metadataPanelActive) {
      return '45%'
    }
    if (tocPanelActive && !metadataPanelActive) {
      return '60%'
    }
    if (!tocPanelActive && metadataPanelActive) {
      return '60%'
    }
    if (!tocPanelActive && !metadataPanelActive) {
      return '80%'
    }
  }

  const getML = () => {
    if (tocPanelActive && metadataPanelActive) {
      return '25%'
    }
    if (tocPanelActive && !metadataPanelActive) {
      return '30%'
    }
    if (!tocPanelActive && metadataPanelActive) {
      return '10%'
    }
    if (!tocPanelActive && !metadataPanelActive) {
      return 'auto'
    }
  }

  const getMR = () => {
    if (tocPanelActive && metadataPanelActive) {
      return '10%'
    }
    if (!tocPanelActive && metadataPanelActive) {
      return '20%'
    }
    if (!tocPanelActive && !metadataPanelActive) {
      return 'auto'
    }
  }

  return (
    <Container maxWidth={'lg'} sx={{ mt: '70px', px: '0 !important' }}>
      {stateAnchor && stateAnchor > '' && (
        <FadeIn visible={!documentScrolled}>
          <Box
            sx={({ palette: { grey } }: Theme) => ({ position: 'fixed', height: '100%', width: '200%', backgroundColor: grey[200], zIndex: 9999, opacity: 0.5, ml: '-1000px' })}
          ></Box>
        </FadeIn>
      )}
      {documentScrolled && <DocumentInfoPanel documentId={documentId || ''} />}
      <Stack
        direction={'row'}
        width={'100%'}
        sx={({ palette: { grey } }: Theme) => ({
          position: 'absolute',
          top: '70px',
          backgroundColor: documentScrolled ? grey[400] : null,
          minHeight: '100vh',
        })}
      >
        <Box
          width="100%"
          sx={({ palette: { grey } }: Theme) => ({
            position: 'fixed',
            width: tocPanelActive ? '400px' : '50px',
            height: '100%',
            backgroundColor: grey[400],
            pb: 2,
            alignItems: 'center',
          })}
        >
          {accessToc && documentScrolled && (
            <TocPanel
              documentId={documentId || ''}
              articleId={stateAnchor || ''}
              anchor={stateAnchor}
              setAnchor={(anchor: string) => handleSetAnchor(anchor)}
              tocPanelActive={tocPanelActive}
              handleSetTocPanelActive={(bool: boolean) => handleSetTocPanelActive(bool)}
              handleEnforceRevisionsTabActive={(bool: boolean) => handleSetRevisionsTabEnforced(bool)}
              revisionsTabEnforced={revisionsTabEnforced}
            />
          )}
        </Box>
        <Box sx={{ position: 'relative', width: getWidth(), ml: getML(), mr: getMR() }}>
          <DocumentScreen
            documentId={documentId || ''}
            anchor={stateAnchor}
            setAnchor={(anchor: string) => handleSetAnchor(anchor)}
            documentScrolled={documentScrolled}
            setDocumentScrolled={(scrolled: boolean) => handleSetDocumentScrolled(scrolled)}
            adminMode={adminPanel}
          />
        </Box>
        <Box
          width="100%"
          sx={({ palette: { grey } }: Theme) => ({
            position: 'fixed',
            width: metadataPanelActive ? '450px' : '150px',
            height: '100%',
            right: '0',
            backgroundColor: grey[400],
            py: 2,
            alignItems: 'center',
          })}
        >
          {documentScrolled && (
            <MetadataPanel
              documentId={documentId || ''}
              articleId={stateAnchor || ''}
              metadataPanelActive={metadataPanelActive}
              handleSetMetadataPanelActive={(bool: boolean) => handleSetMetadataPanelActive(bool)}
              revisionsTabEnforced={revisionsTabEnforced}
            />
          )}
        </Box>
      </Stack>
    </Container>
  )
}

export default ArticleViewer
