import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import logo from 'img/logoSmall.png'
import FadeIn from 'global/fadein'

// @mui material components
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles'

// compendiumX imports
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'

// Functional
import { validateEmail } from './Signup.helpers'

// Context
import { getSignUp } from './Singup.service'

const Signup = () => {
  const [singupLoading, setSignupLoading] = useState(false)
  const [signupStatus, setSignUpStatus] = useState({ code: null, message: '' })
  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState('')
  const [password, setPassword] = useState('')

  const setEmailValidation = (email: string) => {
    const validEmail = validateEmail(email)
    if (validEmail) {
      setEmailValid('valid')
    } else {
      setEmailValid('invalid')
    }
  }

  const setSignUp = async () => {
    setSignupLoading(true)
    await getSignUp(email, password).then((response) =>
      setSignUpStatus({
        code: response?.meta?.code,
        message: response?.meta?.code === 400 ? response.response.errors.map((err: any) => err) : 'success',
      })
    )
    setSignupLoading(false)
  }

  return (
    <Container maxWidth={'lg'} fixed>
      <MDBox mt={2} mx={'auto'} sx={{ maxWidth: '360px' }}>
        <FadeIn visible={singupLoading}>
          <Stack spacing={4} sx={{ alignItems: 'center' }}>
            <CircularProgress size={100} />
            <MDTypography
              variant="h6"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Loading user...
            </MDTypography>
          </Stack>
        </FadeIn>
        <FadeIn visible={!singupLoading}>
          <Stack sx={{ textAlign: 'center' }}>
            <MDBox>
              <img src={logo} alt="Logo" />
            </MDBox>
            {signupStatus.code === 200 && (
              <FadeIn visible={signupStatus.code === 200}>
                <Stack spacing={4} sx={{ alignItems: 'center' }}>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Check your email
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    We sent you a verification link
                  </MDTypography>
                  <RouterLink to="/login">
                    <MDBox
                      mt={8}
                      sx={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                      <MDTypography variant="textSM">Back to log in</MDTypography>
                    </MDBox>
                  </RouterLink>
                </Stack>
              </FadeIn>
            )}
            {signupStatus.code === 400 && (
              <FadeIn visible={signupStatus.code === 400}>
                <Stack spacing={4} sx={{ alignItems: 'center' }}>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Request invalid
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    {signupStatus.message}
                  </MDTypography>
                  <RouterLink to="/login">
                    <MDBox
                      mt={8}
                      sx={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                      <MDTypography variant="textSM">Back to log in</MDTypography>
                    </MDBox>
                  </RouterLink>
                </Stack>
              </FadeIn>
            )}
            {!signupStatus.code && (
              <FadeIn visible={!signupStatus.code}>
                <Stack>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Create an account
                  </MDTypography>

                  <FormControl error={emailValid === 'invalid'} variant="standard" sx={{ mt: 8 }}>
                    <InputLabel shrink htmlFor="email-input">
                      Email
                    </InputLabel>
                    <MDInput
                      placeholder="Enter your company email"
                      type="email"
                      onBlur={(e: { target: { value: string } }) => setEmailValidation(e.target.value)}
                      onChange={(e: { target: { value: string } }) => setEmail(e.target.value)}
                      error={emailValid === 'invalid'}
                    />
                    {emailValid === 'invalid' && <FormHelperText id="component-helper-text">Enter a valid e-mail address</FormHelperText>}
                  </FormControl>

                  <FormControl variant="standard" sx={{ mt: 5, mb: 6 }}>
                    <InputLabel shrink htmlFor="password-input">
                      Password
                    </InputLabel>
                    <MDInput placeholder="Create a password" type="password" onChange={(e: { target: { value: string } }) => setPassword(e.target.value)} />
                    <MDBox sx={{ textAlign: 'start' }}>
                      <MDTypography variant="textSM_M" sx={{ color: 'grey.500' }}>
                        Must be at least 8 characters.
                      </MDTypography>
                    </MDBox>
                  </FormControl>

                  <MDButton type="submit" variant="gradient" color="primary" size="large" disabled={emailValid === 'invalid' || !password} onClick={() => setSignUp()}>
                    Get started
                  </MDButton>

                  <MDBox mt={8}>
                    <MDTypography variant="textSM">
                      Already have an account?
                      <Link href="/login" ml={1}>
                        Log in
                      </Link>
                    </MDTypography>
                  </MDBox>
                </Stack>
              </FadeIn>
            )}
          </Stack>
        </FadeIn>
      </MDBox>
    </Container>
  )
}

export default Signup
