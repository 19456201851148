export const getMetadata = async (articleId?: string): Promise<any> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/article/${articleId}`, {
    credentials: 'include',
    method: 'GET',
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return result
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}

export const getRevisions = async (articleId?: string): Promise<any> => {
  if (articleId) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/article/compare/${articleId}`, {
      credentials: 'include',
      method: 'GET',
    })

    if (response.status > 399) {
      const resp = response.statusText
      return Promise.reject(resp)
    }
    const result = await response.json()
    if (response.ok) {
      return result
    }
    if (!response.ok) {
      const resp: any = response
      return Promise.reject(String(resp?.errors.map((err: any) => err)))
    }
    return result
  }
}

export const shareDevops = async (compxLink: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/share/devops/ticket/${compxLink}`, {
    credentials: 'include',
    method: 'GET',
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return '200'
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}

export const shareJira = async (compxLink: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/share/jira/ticket/${compxLink}`, {
    credentials: 'include',
    method: 'GET',
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return '200'
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}
