import { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from 'img/logoSmall.png'
import confirmed from 'img/confirmed.png'
import FadeIn from 'global/fadein'

// @mui material components
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles'

// compendiumX imports
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'

// Functional
import { validateEmail } from './Reset.helpers'
import { getReset } from './Reset.service'

const Reset = () => {
  const [resetLoading, setResetLoading] = useState(false)
  const [resetStatus, setResetStatus] = useState()
  const [resetMessage, setResetMessage] = useState<any>()
  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState('')

  const setEmailValidation = (email: string) => {
    const validEmail = validateEmail(email)
    if (validEmail) {
      setEmailValid('valid')
    } else {
      setEmailValid('invalid')
    }
  }

  const setReset = async () => {
    setResetLoading(true)
    await getReset(email).then((response) => {
      setResetStatus(response.meta.code)
      response.meta.code === 400 ? setResetMessage(response?.response?.errors[0]) : console.log('Reset successful')
    })
    setResetLoading(false)
  }

  return (
    <Container maxWidth={'lg'} fixed>
      <MDBox mt={2} mx={'auto'} sx={{ maxWidth: '360px' }}>
        <FadeIn visible={resetLoading}>
          <Stack spacing={4} sx={{ alignItems: 'center' }}>
            <CircularProgress size={100} />
            <MDTypography
              variant="h6"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Loading reset...
            </MDTypography>
          </Stack>
        </FadeIn>
        <FadeIn visible={!resetLoading}>
          <Stack sx={{ textAlign: 'center' }}>
            <MDBox>{<img src={resetStatus === 200 ? confirmed : logo} alt="Logo" />}</MDBox>
            {resetStatus === 200 && (
              <FadeIn visible={resetStatus === 200}>
                <Stack spacing={4} sx={{ alignItems: 'center' }}>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Check your email
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    We sent you a password reset link
                  </MDTypography>
                  <Link to="/login">
                    <MDBox
                      mt={8}
                      sx={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                      <MDTypography variant="textSM">Back to log in</MDTypography>
                    </MDBox>
                  </Link>
                </Stack>
              </FadeIn>
            )}
            {resetStatus === 400 && (
              <FadeIn visible={resetStatus === 400}>
                <Stack spacing={4} sx={{ alignItems: 'center' }}>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Request invalid
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    Something went wrong
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    {resetMessage}
                  </MDTypography>
                  <Link to="/login">
                    <MDBox
                      mt={8}
                      sx={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                      <MDTypography variant="textSM">Back to log in</MDTypography>
                    </MDBox>
                  </Link>
                </Stack>
              </FadeIn>
            )}
            {!resetStatus && (
              <FadeIn visible={!resetStatus}>
                <Stack>
                  <MDTypography
                    variant="displaySM"
                    mt={6}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[900],
                    })}
                  >
                    Forgotten password?
                  </MDTypography>
                  <MDTypography
                    variant="textMD"
                    mt={2}
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[500],
                    })}
                  >
                    No worries, we’ll send you reset instructions.
                  </MDTypography>
                  <FormControl error={emailValid === 'invalid'} variant="standard" sx={{ mt: 8, mb: 6 }}>
                    <InputLabel shrink htmlFor="email-input">
                      Email
                    </InputLabel>
                    <MDInput
                      placeholder="Enter your email"
                      type="email"
                      onBlur={(e: { target: { value: string } }) => setEmailValidation(e.target.value)}
                      onChange={(e: { target: { value: string } }) => setEmail(e.target.value)}
                      error={emailValid === 'invalid'}
                    />
                    {emailValid === 'invalid' && <FormHelperText id="component-helper-text">Enter a valid e-mail address</FormHelperText>}
                  </FormControl>
                  <MDButton type="submit" variant="gradient" color="primary" size="large" disabled={emailValid === 'invalid'} onClick={() => setReset()}>
                    Reset password
                  </MDButton>
                  <Link to="/login">
                    <MDBox
                      mt={8}
                      sx={{
                        cursor: 'pointer',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                      <MDTypography variant="textSM">Back to log in</MDTypography>
                    </MDBox>
                  </Link>
                </Stack>
              </FadeIn>
            )}
          </Stack>
        </FadeIn>
      </MDBox>
    </Container>
  )
}

export default Reset
