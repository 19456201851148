import { useState } from 'react'
import FadeIn from 'global/fadein'

// @mui material components
import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/system/Stack'
import { Theme } from '@mui/material/styles/createTheme'
import InputAdornment from '@mui/material/InputAdornment/InputAdornment'
import Icon from '@mui/material/Icon'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'

// compendiumX imports
import MDBox from 'components/MDBox'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'

// Avatars
import one from 'img/avatars/1.png'
import two from 'img/avatars/2.png'
import three from 'img/avatars/3.png'
import four from 'img/avatars/4.png'
import five from 'img/avatars/5.png'
import six from 'img/avatars/6.png'
import seven from 'img/avatars/7.png'
import eight from 'img/avatars/8.png'
import MDButton from 'components/MDButton'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

const Profile = () => {
  //Module specific
  const [loading] = useState(false)

  const avatars = [one, two, three, four, five, six, seven, eight]

  const roles = ['Finance', 'Lawyer']
  const [role, setRole] = useState('')

  const documents = ['Annex 1', 'Annex 2', 'Annex 3', 'Annex 4']
  const collections = ['Single rulebook', 'Q&A', 'ITS']

  const handleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value)
  }

  return (
    <Container maxWidth={'lg'} fixed sx={{ mt: 10 }}>
      <MDBox>
        {loading && (
          <FadeIn visible={loading}>
            <Stack spacing={4} sx={{ alignItems: 'center' }}>
              <CircularProgress size={100} />
              <MDTypography
                variant="h6"
                sx={({ palette: { grey } }: Theme) => ({
                  color: grey[500],
                })}
              >
                Loading content...
              </MDTypography>
            </Stack>
          </FadeIn>
        )}
        {!loading && (
          <FadeIn>
            {/* Start personal details */}
            <Stack sx={{ mx: 'auto', width: '800px' }}>
              <MDBox
                sx={{
                  textAlign: 'flex-start',
                }}
              >
                <MDBox>
                  <MDTypography variant="displaySM_M">Personal info</MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="textSM" sx={{ color: 'grey.500' }}>
                    Update your personal details here.
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mt={6}>
                <Divider sx={{ mb: 3, mt: '0 !important' }} />
                <Stack divider={<Divider sx={{ mb: 5 }} />}>
                  <MDBox sx={{ display: 'flex' }}>
                    <MDBox sx={{ display: 'inline-flex', width: '30%' }}>
                      <MDTypography variant="textSM" sx={{ color: 'grey.700' }}>
                        Name
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ width: '70%' }}>
                      <MDInput placeholder="First name" onChange={(e: { target: { value: string } }) => console.log(e.target.value)} sx={{ mr: 6, width: '45%' }} />
                      <MDInput placeholder="Last name" onChange={(e: { target: { value: string } }) => console.log(e.target.value)} sx={{ width: '45%' }} />
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{ display: 'flex' }}>
                    <MDBox sx={{ display: 'inline-flex', width: '30%' }}>
                      <MDTypography variant="textSM" sx={{ color: 'grey.700' }}>
                        Email address
                      </MDTypography>
                    </MDBox>
                    <MDBox width={'70%'}>
                      <MDInput
                        placeholder="Email"
                        onChange={(e: { target: { value: string } }) => console.log(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon baseClassName="material-icons-outlined">mail</Icon>
                            </InputAdornment>
                          ),
                        }}
                        sx={{ width: '100%' }}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{ display: 'flex' }}>
                    <MDBox sx={{ display: 'inline-flex', width: '30%' }}>
                      <MDTypography variant="textSM" sx={{ color: 'grey.700' }}>
                        Your avatar
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ display: 'inline-flex', width: '30%', gap: 6 }}>
                      {avatars.map((avatar) => {
                        return (
                          <IconButton sx={{ p: 0 }}>
                            <Avatar alt={avatar} src={avatar} />
                          </IconButton>
                        )
                      })}
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{ display: 'flex' }}>
                    <MDBox sx={{ display: 'inline-flex', width: '30%' }}>
                      <MDTypography variant="textSM" sx={{ color: 'grey.700' }}>
                        Role
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ width: '70%' }}>
                      <Select
                        input={<OutlinedInput sx={{ width: '100%' }} />}
                        value={role}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        renderValue={() => {
                          return (
                            <MDBox
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '47px',
                              }}
                            >
                              <MDTypography variant="textMD_M" ml={'8px'}>
                                {role}
                              </MDTypography>
                              <Icon sx={{ ml: 'auto' }}>expand_more</Icon>
                            </MDBox>
                          )
                        }}
                      >
                        {roles.map((role) => (
                          <MenuItem value={role}>{role}</MenuItem>
                        ))}
                      </Select>
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{ display: 'inline-flex', flexDirection: 'row-reverse' }}>
                    <MDButton variant="gradient" color="primary" sx={{ ml: 2 }}>
                      Save
                    </MDButton>
                  </MDBox>
                </Stack>
              </MDBox>
            </Stack>
            {/* End personal details */}
            {/* Start password reset */}
            <Stack sx={{ mt: 10, mx: 'auto', width: '800px' }}>
              <MDBox
                sx={{
                  textAlign: 'flex-start',
                }}
              >
                <MDBox>
                  <MDTypography variant="displaySM_M">Password</MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="textSM" sx={{ color: 'grey.500' }}>
                    Please enter your current password to change your password.
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mt={6}>
                <Divider sx={{ mb: 3, mt: '0 !important' }} />
                <Stack divider={<Divider sx={{ mb: 5 }} />}>
                  <MDBox sx={{ display: 'flex' }}>
                    <MDBox sx={{ display: 'inline-flex', width: '30%' }}>
                      <MDTypography variant="textSM" sx={{ color: 'grey.700' }}>
                        Current password
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ width: '70%' }}>
                      <MDInput
                        placeholder="Current password"
                        type="password"
                        onChange={(e: { target: { value: string } }) => console.log(e.target.value)}
                        sx={{ mr: 6, width: '100%' }}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{ display: 'flex' }}>
                    <MDBox sx={{ display: 'inline-flex', width: '30%' }}>
                      <MDTypography variant="textSM" sx={{ color: 'grey.700' }}>
                        New password
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ width: '70%' }}>
                      <MDInput
                        placeholder="New password"
                        type="password"
                        onChange={(e: { target: { value: string } }) => console.log(e.target.value)}
                        sx={{ mr: 6, width: '100%' }}
                      />
                      <MDTypography variant="textSM" sx={{ color: 'grey.500' }}>
                        Your new password must be more than 8 characters.
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{ display: 'flex' }}>
                    <MDBox sx={{ display: 'inline-flex', width: '30%' }}>
                      <MDTypography variant="textSM" sx={{ color: 'grey.700' }}>
                        Confirm new password
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ width: '70%' }}>
                      <MDInput
                        placeholder="New password"
                        type="password"
                        onChange={(e: { target: { value: string } }) => console.log(e.target.value)}
                        sx={{ mr: 6, width: '100%' }}
                      />
                    </MDBox>
                  </MDBox>
                </Stack>
              </MDBox>
              <Divider sx={{ my: 5 }} />
              <MDBox sx={{ display: 'inline-flex', flexDirection: 'row-reverse' }}>
                <MDButton variant="gradient" color="primary" sx={{ ml: 2 }}>
                  Save
                </MDButton>
              </MDBox>
            </Stack>
            {/* End password reset */}
            {/* Start checkboxes */}
            <Stack sx={{ mt: 10, mx: 'auto', width: '800px' }}>
              <MDBox
                sx={{
                  textAlign: 'flex-start',
                }}
              >
                <MDBox>
                  <MDTypography variant="displaySM_M">Personal filters</MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="textSM" sx={{ color: 'grey.500' }}>
                    The filters you choose here will be preselected everytime you do a search.
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mt={6}>
                <Divider sx={{ mb: 3, mt: '0 !important' }} />
                <Stack divider={<Divider sx={{ mb: 5 }} />}>
                  <MDBox sx={{ display: 'flex' }}>
                    <MDBox sx={{ display: 'inline-flex', width: '30%' }}>
                      <MDTypography variant="textSM" sx={{ color: 'grey.700' }}>
                        Documents
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ width: '70%' }}>
                      {documents.map((document) => (
                        <FormControlLabel label={document} sx={{ mb: 1 }} control={<Checkbox color="secondary" checked={true} onChange={() => console.log('te')} />} />
                      ))}
                    </MDBox>
                  </MDBox>
                  <MDBox sx={{ display: 'flex' }}>
                    <MDBox sx={{ display: 'inline-flex', width: '30%' }}>
                      <MDTypography variant="textSM" sx={{ color: 'grey.700' }}>
                        Collections
                      </MDTypography>
                    </MDBox>
                    <MDBox sx={{ width: '70%' }}>
                      {collections.map((collection) => (
                        <FormControlLabel label={collection} sx={{ mb: 1 }} control={<Checkbox color="secondary" checked={true} onChange={() => console.log('te')} />} />
                      ))}
                    </MDBox>
                  </MDBox>
                </Stack>
              </MDBox>
              <Divider sx={{ my: 5 }} />
              <MDBox sx={{ display: 'inline-flex', flexDirection: 'row-reverse' }}>
                <MDButton variant="gradient" color="primary" sx={{ ml: 2 }}>
                  Save
                </MDButton>
              </MDBox>
            </Stack>
            {/* End checkboxes */}
          </FadeIn>
        )}
      </MDBox>
    </Container>
  )
}

export default Profile
