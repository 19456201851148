import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FadeIn from 'global/fadein'

// @mui material components
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import Stack from '@mui/system/Stack'
import { Theme } from '@mui/material/styles/createTheme'

// compendiumX imports
import MDTypography from 'components/MDTypography'
import ModuleWrapper from 'wrappers/ModuleWrapper'

// Functional
import { getRecentSearchData } from './RecentSearch.service'

// Context
import { ErrorContext } from 'context/ErrorContext'
import { SearchContext } from 'context/SearchContext'

// Types
import RecentSearchTermProps from './RecentSearch.type'

const RecentSearch = () => {
  const navigate = useNavigate()
  const { setErrorMessage } = useContext(ErrorContext)

  const { setRecentTab } = useContext(SearchContext)
  //Module specific
  const [loading, setLoading] = useState(false)
  //Data
  const [recentSearchData, setRecentSearchData] = useState<RecentSearchTermProps[]>([])

  useEffect(() => {
    const fetchRecentSearch = async (): Promise<void> => {
      setLoading(true)
      try {
        setRecentSearchData(await getRecentSearchData())
      } catch (error: any) {
        setErrorMessage(error)
      }
      setLoading(false)
    }
    fetchRecentSearch()
    // eslint-disable-next-line
  }, [])

  const goToSearch = async (recentSearchItem: RecentSearchTermProps) => {
    await setRecentTab(recentSearchItem.searchterm)
    navigate(`/search/`)
    window.location.reload()
  }

  const recentSearchItemTemplate = (recentSearchItem: RecentSearchTermProps, index: number) => {
    return (
      <Chip
        key={index}
        label={recentSearchItem.searchterm}
        icon={
          <Icon
            sx={({ palette: { indigo } }: Theme) => ({
              color: `${indigo.additional} !important`,
            })}
          >
            search
          </Icon>
        }
        onClick={() => goToSearch(recentSearchItem)}
        sx={({ palette: { indigo }, typography: { size } }: Theme) => ({
          backgroundColor: indigo.main,
          fontSize: size.sm,
          color: indigo.additional,
        })}
      />
    )
  }

  return (
    <ModuleWrapper moduleName="Recent search">
      {loading && (
        <FadeIn visible={loading}>
          <Stack spacing={4} sx={{ alignItems: 'center' }}>
            <CircularProgress size={100} />
            <MDTypography
              variant="h6"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Loading data...
            </MDTypography>
          </Stack>
        </FadeIn>
      )}
      {!loading && (
        <FadeIn>
          <Stack direction={'row'} spacing={2}>
            {recentSearchData.map((recentSearchItem, index) => recentSearchItemTemplate(recentSearchItem, index))}
          </Stack>
        </FadeIn>
      )}
    </ModuleWrapper>
  )
}

export default RecentSearch
