// Types
import { SearchSuggestionProps } from './SearchContainer.type'

export async function getSearchSuggestions(searchTerm?: string): Promise<{
  autocomplete: SearchSuggestionProps[]
  suggestion: SearchSuggestionProps[]
  recent_searchterms: SearchSuggestionProps[]
}> {
  const token = localStorage.getItem('authentication-Token')
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/search/suggestion?search=${searchTerm || '*'}`, {
      method: 'GET',
      headers: {
        'authentication-Token': token || '',
      },
    })
    const result = await response.json()
    return result
  } catch (error) {
    return { autocomplete: [], suggestion: [], recent_searchterms: [] }
  }
}

export async function getPopularSearchTerms(): Promise<any> {
  const token = localStorage.getItem('authentication-Token')
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/entry/v1/searchterm/popular/platform`, {
      method: 'GET',
      headers: {
        'authentication-Token': token || '',
      },
    })
    const result = await response.json()
    return result
  } catch (error) {
    return {}
  }
}
