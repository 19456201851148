import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from 'img/logoLogin.png'
import FadeIn from 'global/fadein'

// @mui material components
// import Checkbox from "@mui/material/Checkbox";
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import { CXIconMicrosoft } from 'components/CXIcon/CXIcon'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles'

// compendiumX imports
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'

// Functional
import { validateEmail } from './Login.helpers'

// Context
import { UserContext } from 'context/UserContext'

const Login = () => {
  const navigate = useNavigate()
  const { userLoading, loginUser, userError, setUserTrigger } = useContext(UserContext)
  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false)

  useEffect(() => {
    if (userError?.startsWith('error')) {
      setLoginError(true)
    }
    // eslint-disable-next-line
  }, [userError])

  const setEmailValidation = (email: string) => {
    const validEmail = validateEmail(email)
    if (validEmail) {
      setEmailValid('valid')
    } else {
      setEmailValid('invalid')
    }
  }

  const setLogin = async () => {
    loginUser(email, password)
  }

  // Login popup //
  const [token, setToken] = useState('')

  useEffect(() => {
    const lastLocation: string | null = localStorage.getItem('lastLocation')
    if (token > '') {
      navigate(lastLocation || '/dashboard')
      setUserTrigger(Date.now())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const openLoginPopup = () => {
    const loginURL: string = `${process.env.REACT_APP_SSO_URL}`
    const windowFeatures: string = 'width=600,height=400,resizable,scrollbars=yes,status=1'
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const popup: Window | null = window.open(loginURL, 'Login', windowFeatures)

    // Handle the message from popup
    window.addEventListener(
      'message',
      (event: MessageEvent) => {
        const data: any = event.data
        setToken(data.token)
      },
      false
    )
  }

  return (
    <Container maxWidth={'lg'} fixed>
      <MDBox mt={9} mx={'auto'} sx={{ maxWidth: '360px' }}>
        {userLoading && (
          <FadeIn visible={userLoading}>
            <Stack spacing={4} sx={{ alignItems: 'center' }}>
              <CircularProgress size={100} />
              <MDTypography
                variant="h6"
                sx={({ palette: { grey } }: Theme) => ({
                  color: grey[500],
                })}
              >
                Loading user...
              </MDTypography>
            </Stack>
          </FadeIn>
        )}
        {loginError && (
          <FadeIn visible={loginError}>
            <Stack spacing={4} sx={{ alignItems: 'center' }}>
              <MDBox>
                <img src={logo} alt="Logo" width="192px" />
              </MDBox>
              <MDTypography
                variant="displaySM"
                mt={6}
                sx={({ palette: { grey } }: Theme) => ({
                  color: grey[900],
                })}
              >
                Request invalid
              </MDTypography>
              <MDTypography
                variant="textMD"
                mt={2}
                sx={({ palette: { grey } }: Theme) => ({
                  color: grey[500],
                })}
              >
                Something went wrong, please try again
              </MDTypography>
              <MDTypography
                variant="textMD"
                mt={2}
                sx={({ palette: { grey } }: Theme) => ({
                  color: grey[500],
                })}
              >
                {userError}
              </MDTypography>
              {/* Todo change this if else to something that recognizes error above */}
              {userError?.startsWith('Email requires confirmation') ? (
                <MDBox
                  mt={8}
                  sx={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => navigate('/verify')}
                >
                  <Icon sx={{ mr: 3 }}>arrow_forward</Icon>
                  <MDTypography variant="textSM">Proceed to the account verification page</MDTypography>
                </MDBox>
              ) : (
                <MDBox
                  mt={8}
                  sx={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => setLoginError(false)}
                >
                  <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                  <MDTypography variant="textSM">Back to log in</MDTypography>
                </MDBox>
              )}
            </Stack>
          </FadeIn>
        )}
        <FadeIn visible={!userLoading && !loginError}>
          <Stack sx={{ textAlign: 'center' }}>
            <MDBox>
              <img src={logo} alt="Logo" width="192px" />
            </MDBox>
            <MDTypography
              variant="displaySM"
              mt={6}
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[900],
              })}
            >
              Log in to your account
            </MDTypography>
            <MDTypography
              variant="textMD"
              mt={2}
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Please enter your details.
            </MDTypography>
            <MDBox
              component="form"
              onSubmit={(e) => {
                e.preventDefault()
                setLogin()
              }}
            >
              <FormControl error={emailValid === 'invalid'} variant="standard" sx={{ mt: 8, width: '100%' }}>
                <InputLabel shrink htmlFor="email-input">
                  Email
                </InputLabel>
                <MDInput
                  placeholder="Enter your email"
                  type="email"
                  onBlur={(e: { target: { value: string } }) => setEmailValidation(e.target.value)}
                  onChange={(e: { target: { value: string } }) => setEmail(e.target.value)}
                  error={emailValid === 'invalid'}
                />
                {emailValid === 'invalid' && <FormHelperText id="component-helper-text">Enter a valid e-mail address</FormHelperText>}
              </FormControl>
              <FormControl variant="standard" sx={{ mt: 5, width: '100%' }}>
                <InputLabel shrink htmlFor="password-input">
                  Password
                </InputLabel>
                <MDInput placeholder="Enter your password" type="password" onChange={(e: { target: { value: string } }) => setPassword(e.target.value)} />
              </FormControl>
              <MDBox
                my={6}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {/* <MDBox>
                  <Checkbox sx={{ pl: 0 }} />
                  <MDTypography variant="textSM_M">Remember me</MDTypography>
                </MDBox> */}
                <MDTypography sx={{ ml: 'auto' }} variant="textSM_M">
                  <Link href="/reset">Forgot password</Link>
                </MDTypography>
              </MDBox>
              <MDButton type="submit" variant="gradient" color="primary" size="large" fullWidth disabled={emailValid === 'invalid' || !password}>
                Sign in
              </MDButton>
            </MDBox>
            <Divider />
            <MDBox>
              <MDButton variant="outlined" color="dark" size="large" fullWidth onClick={() => openLoginPopup()}>
                <CXIconMicrosoft sx={{ mr: 1 }} />
                Continue with Microsoft account
              </MDButton>
            </MDBox>
            <MDBox mt={8}>
              <MDTypography variant="textSM">
                Don't have an account?
                <Link href="/signup" ml={1}>
                  Sign up
                </Link>
              </MDTypography>
            </MDBox>
            <MDBox mt={2}>
              <MDTypography variant="textSM">
                Please contact
                <Link href={`mailto:support@compendium-x.com`} ml={1}>
                  support@compendium-x.com
                </Link>
              </MDTypography>
              <MDTypography variant="textSM" ml={1}>
                for any questions
              </MDTypography>
            </MDBox>
          </Stack>
        </FadeIn>
      </MDBox>
    </Container>
  )
}

export default Login
