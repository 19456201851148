export const getSignUp = async (email: string, password: string) => {
  const credentials = {
    email: email,
    password: password,
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_API_LOGIN_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
    const result = await response.json()
    if (response.ok) {
      return result
    } else {
      return result
    }
  } catch (error) {
    return error
  }
}
