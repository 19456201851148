import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import FadeIn from 'global/fadein'
import { helix } from 'ldrs'

// @mui material components
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
// import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import Stack from '@mui/system/Stack'
import { Theme } from '@mui/material/styles/createTheme'

// compendiumX imports
import { CXIconArticle, CXIconTemplate } from 'components/CXIcon/CXIcon'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
// import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import ModuleWrapper from 'wrappers/ModuleWrapper'

// Submodules
import { SearchResultBreadcrumb } from './SearchResults.components'

// Context
import { ErrorContext } from 'context/ErrorContext'
import { SearchContext } from 'context/SearchContext'
import { UserContext } from 'context/UserContext'

// Functional
import { getAiSuggestion, getSearchResultsCount, getSearchResultsData } from './SearchResults.service'

// Types
import SearchResultItemProps, { SearchResultRelatedArticle, SearchResultRelatedTemplate } from './SearchResults.type'

const SearchResults = () => {
  const navigate = useNavigate()
  helix.register()

  // User information
  const { userRoles } = useContext(UserContext)
  const aiAccess = userRoles && userRoles?.indexOf('ai_assistant') > -1

  // Context
  const { setErrorMessage } = useContext(ErrorContext)
  const {
    activeSearchTab,
    searchTerm,
    searchFilters,
    searchSorter,
    searchActivePage,
    filterDocuments,
    filterDocumentCollection,
    filterDate,
    getSetSearchActivePage,
    getSetTabCount,
  } = useContext(SearchContext)

  // Module specific
  const [loading, setLoading] = useState(false)
  const [collapsed, setCollapsed] = useState('')

  // AI suggestion
  const [aiLoading, setAiLoading] = useState(false)
  const [aiResult, setAiResult] = useState<SearchResultItemProps>()
  const [aiCollapsed, setAiCollapsed] = useState(false)

  // Paging
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    window.scrollTo(0, 0)
    getSetSearchActivePage(page)
  }

  // Data
  const [searchResultsData, setSearchResultsData] = useState<SearchResultItemProps[]>([])

  // Search Count
  const [searchResultsCount, setSearchResultsCount] = useState<number>(0)
  useEffect(() => {
    getSetTabCount(searchResultsCount)
    // eslint-disable-next-line
  }, [searchResultsCount])

  const truncateString = (string: string) => {
    return string?.length > 45 ? string.slice(0, 45 - 1) + '...' : string
  }

  const truncateDescription = (string: string) => {
    return string?.length > 500 ? string.slice(0, 500 - 1) + '...' : string
  }

  const emptySearchFilters = activeSearchTab.searchFilters && Object.values(activeSearchTab?.searchFilters).every((value) => value === null)

  useEffect(() => {
    const fetchSearchResultsData = async (): Promise<void> => {
      setLoading(true)
      try {
        setSearchResultsData(await getSearchResultsData(searchTerm || '', searchActivePage, searchFilters, searchSorter, filterDocuments, filterDocumentCollection, filterDate))
        setSearchResultsCount(await getSearchResultsCount(searchTerm || '', searchFilters, filterDocuments, filterDocumentCollection, filterDate))
      } catch (error: any) {
        setErrorMessage(error)
      }
      setLoading(false)
    }

    if (searchTerm || searchFilters || filterDocuments || filterDocumentCollection || filterDate) {
      fetchSearchResultsData()
    }
    // eslint-disable-next-line
  }, [searchTerm, searchFilters, searchSorter, searchActivePage, filterDocuments, filterDocumentCollection, filterDate])

  useEffect(() => {
    const fetchAiSuggestion = async (): Promise<void> => {
      setAiLoading(true)
      try {
        setAiResult(await getAiSuggestion(searchTerm || '',
          searchFilters,
          filterDocuments,
          filterDocumentCollection,
          filterDate))
      } catch (error: any) {
        setErrorMessage(error)
      }
      setAiLoading(false)
    }

    if (
    aiAccess &&
    !aiLoading &&
    searchResultsData.length > 0 &&
    searchTerm &&
    searchTerm > ''
    ) {
      fetchAiSuggestion()
    }
    // eslint-disable-next-line
  }, [searchResultsData, aiAccess])

  const searchResultsTemplate = (searchResult: SearchResultItemProps, highlighted?: boolean) => {
    const relatedContentArticle = (relatedItem: SearchResultRelatedArticle) => {
      return (
        <MDBox
          p={'8px'}
          my={2}
          width="100%"
          sx={({ borders: { borderColor, borderWidth, borderRadius } }: Theme) => ({
            border: `${borderWidth[1]} solid ${borderColor}`,
            borderRadius: borderRadius.lg,
            display: 'inline-flex',
            '&:hover': {
              cursor: 'pointer',
              boxShadow: '6px 6px 12px rgba(0, 0, 0, 0.06)',
            },
          })}
          onClick={() => {
            window.location.href = `/article/${relatedItem.document_id}/${relatedItem.article_anchor || ''}`
          }}
        >
          <MDBox ml={3} height="40px" width="40px" sx={{ color: '#2B2B73', display: 'flex', alignItems: 'center' }}>
            <CXIconArticle />
          </MDBox>
          <Stack>
            <MDTypography
              variant="caption1"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[800],
              })}
            >
              {relatedItem.document_shortname}: {relatedItem.article_nr}
            </MDTypography>
            <MDTypography
              variant="caption1"
              sx={({ typography: { fontWeightRegular } }: Theme) => ({
                color: '#2B2B73',
                fontWeight: fontWeightRegular,
              })}
            >
              {highlighted ? relatedItem.article_title : truncateString(relatedItem.article_title)}
            </MDTypography>
            {highlighted && relatedItem.article_relevance && (
              <MDTypography
                variant="caption1"
                sx={({ palette: { grey } }: Theme) => ({
                  color: grey[600],
                })}
              >
                <b>Relevance: </b>
                {relatedItem.article_relevance}
              </MDTypography>
            )}
          </Stack>
        </MDBox>
      )
    }
    const relatedContentTemplate = (relatedItem: SearchResultRelatedTemplate) => {
      return (
        <MDBox
          p={'8px'}
          my={2}
          width="100%"
          sx={({ borders: { borderColor, borderWidth, borderRadius } }: Theme) => ({
            border: `${borderWidth[1]} solid ${borderColor}`,
            borderRadius: borderRadius.lg,
            display: 'inline-flex',
            '&:hover': {
              cursor: 'pointer',
              boxShadow: '6px 6px 12px rgba(0, 0, 0, 0.06)',
            },
          })}
        >
          <MDBox ml={3} height="40px" width="40px" sx={{ color: '#2B2B73', display: 'flex', alignItems: 'center' }}>
            <CXIconTemplate />
          </MDBox>
          <Stack>
            <MDTypography
              variant="caption1"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[800],
              })}
            >
              {relatedItem.TaxonomyCode}: {relatedItem.TemplateCode}
            </MDTypography>
            <MDTypography
              variant="caption1"
              sx={({ typography: { fontWeightRegular } }: Theme) => ({
                color: '#2B2B73',
                fontWeight: fontWeightRegular,
              })}
            >
              {truncateString(relatedItem.TemplateLabel)}
            </MDTypography>
          </Stack>
        </MDBox>
      )
    }
    const searchResultLabels = [searchResult.document_collection]
    return (
      <MDBox
        sx={{
          backgroundColor: highlighted ? 'indigo.main' : 'white.main',
          borderRadius: 1,
          border: '1px solid #EAECF0',
          p: 5,
          position: 'relative',
        }}
      >
        <MDBox
          pb={4}
          sx={({ borders: { borderColor, borderWidth } }: Theme) => ({
            borderBottom: !highlighted ? `${borderWidth[1]} solid ${borderColor}` : null,
          })}
        >
          {!highlighted && (
            <MDBox sx={{ position: 'absolute' }}>
              <SearchResultBreadcrumb
                document_name={searchResult.document_longname}
                document_link={searchResult.document_id}
                article_title={`${searchResult.article_nr}: ${searchResult.article_title}`}
                article_link={`${searchResult.document_id}/${searchResult.article_anchor}`}
                secondLast_name={searchResult.toc_path_long_object?.slice(searchResult.toc_path_long_object.length - 1, searchResult.toc_path_long_object.length)[0]?.toc_nr}
                rest={
                  <>
                    {searchResult.document_name}
                    {' > '}
                    <b>
                      {searchResult.toc_path_long_object
                        ?.slice(0, searchResult.toc_path_long_object.length - 1)
                        .map((o) => `${o?.toc_nr} ${o?.toc_title}`)
                        .join(' > ')}
                    </b>
                    {' > '}
                    {searchResult.toc_path_long_object
                      ?.slice(searchResult.toc_path_long_object.length - 1, searchResult.toc_path_long_object.length)
                      .map((o) => `${o?.toc_nr} ${o?.toc_title}`)}
                    {' > '}
                    {searchResult.article_title}
                  </>
                }
                secondLast={
                  <>
                    {searchResult.document_name}
                    {' > '}
                    {searchResult.toc_path_long_object
                      ?.slice(0, searchResult.toc_path_long_object.length - 1)
                      .map((o) => `${o?.toc_nr} ${o?.toc_title}`)
                      .join(' > ')}
                    {' > '}
                    <b>
                      {searchResult.toc_path_long_object
                        ?.slice(searchResult.toc_path_long_object.length - 1, searchResult.toc_path_long_object.length)
                        .map((o) => `${o?.toc_nr} ${o?.toc_title}`)}
                    </b>
                    {' > '}
                    {searchResult.article_title}
                  </>
                }
              />
            </MDBox>
          )}
          <MDBox
            sx={{
              display: 'flex',
              flexDirection: highlighted ? 'row' : 'row-reverse',
              columnGap: 1,
            }}
          >
            {searchResultLabels
              .filter((label) => label !== '')
              .map((label) => (
                <Chip
                  label={highlighted ? 'AI-assistant' : label}
                  sx={{
                    px: 4,
                    backgroundColor: highlighted ? 'indigo.focus' : 'indigo.main',
                    color: highlighted ? 'indigo.main' : 'indigo.focus',
                  }}
                />
              ))}
          </MDBox>
          <MDBox>
            <>
              {!highlighted && (
                <MDBox
                  mt={5}
                  sx={{
                    display: 'inline-flex',
                  }}
                >
                  <MDTypography
                    sx={({ typography: { size, fontWeightMedium } }: Theme) => ({
                      fontSize: size.md,
                      fontWeight: fontWeightMedium,
                      fontStyle: 'italic',
                      color: '#4343B2',
                    })}
                  >
                    {moment(searchResult.document_date).format('DD-MM-YYYY') + ' /'}
                  </MDTypography>
                  <MDTypography
                    ml={2}
                    sx={({ typography: { size, fontWeightMedium } }: Theme) => ({
                      fontSize: size.md,
                      fontWeight: fontWeightMedium,
                      fontStyle: 'italic',
                      color: '#4343B2',
                    })}
                  >
                    Relevancy:
                    <MDTypography
                      ml={2}
                      sx={({ palette: { error, warning, success, primary }, typography: { fontWeightSemiBold } }: Theme) => ({
                        fontSize: 'inherit',
                        fontWeight: fontWeightSemiBold,
                        fontStyle: 'normal',
                        display: 'inline-block',
                        // color:,
                        // searchResult.search_score >= 90
                        //   ? success.main
                        //   : searchResult.search_score >= 50
                        //   ? warning.main
                        //   : error.main,
                      })}
                    >
                      {Math.ceil(searchResult.search_score)}%
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              )}
              <MDBox
                mt={3}
                sx={{
                  cursor: !highlighted ? 'pointer' : 'default',
                }}
                onClick={() => !highlighted && navigate(`/article/${searchResult.document_id}/${searchResult.article_anchor || ''}`)}
              >
                <MDTypography
                  sx={({ palette: { grey }, typography: { size, fontWeightMedium } }: Theme) => ({
                    fontSize: size.md,
                    fontWeight: fontWeightMedium,
                    color: grey[900],
                    '& .highlights': {
                      backgroundColor: '#FEF0C7',
                    },
                  })}
                  dangerouslySetInnerHTML={{
                    __html: !highlighted
                      ? truncateDescription(searchResult?.article_content_highlights && searchResult?.article_content_highlights.map((highlight) => highlight).join('. . . ')) ||
                        truncateDescription(searchResult?.article_content)
                      : (searchResult?.article_content_highlights && searchResult?.article_content_highlights.map((highlight) => highlight).join('. . . ')) ||
                        searchResult?.article_content,
                  }}
                ></MDTypography>
              </MDBox>
            </>
          </MDBox>
        </MDBox>
        <MDBox mt={4}>
          {!highlighted && (
            <Stack direction={'row'} justifyContent="space-between" divider={<Divider orientation="vertical" flexItem />}>
              <MDBox width={highlighted ? '100%' : '50%'}>
                <MDTypography
                  mb={2}
                  sx={({ palette: { grey }, typography: { size, fontWeightMedium } }: Theme) => ({
                    fontSize: size.lg,
                    fontWeight: fontWeightMedium,
                    color: grey[900],
                  })}
                >
                  Related articles {`(${searchResult.related_articles?.length || 0})`}
                </MDTypography>
                <MDBox pr={2}>
                  {searchResult.related_articles?.slice(0, 2)?.map((article) => relatedContentArticle(article))}
                  <Collapse in={collapsed === searchResult.article_id}>
                    {searchResult.related_articles?.slice(2, searchResult.related_articles?.length)?.map((article) => relatedContentArticle(article))}
                  </Collapse>
                </MDBox>
              </MDBox>
              {!highlighted && (
                <MDBox width={'50%'}>
                  <MDTypography
                    mb={2}
                    sx={({ palette: { grey }, typography: { size, fontWeightMedium } }: Theme) => ({
                      fontSize: size.lg,
                      fontWeight: fontWeightMedium,
                      color: grey[900],
                    })}
                  >
                    Related templates {`(${searchResult.related_templates?.length || 0})`}
                  </MDTypography>
                  <MDBox pr={2}>
                    {searchResult.related_templates?.slice(0, 2).map((template) => relatedContentTemplate(template))}
                    <Collapse in={collapsed === searchResult.article_id}>
                      {searchResult.related_templates?.slice(2, searchResult.related_templates?.length).map((template) => relatedContentTemplate(template))}
                    </Collapse>
                  </MDBox>
                </MDBox>
              )}
            </Stack>
          )}
          {highlighted && aiCollapsed && (
            <Stack direction={'row'} justifyContent="space-between" divider={<Divider orientation="vertical" flexItem />}>
              <MDBox width={highlighted ? '100%' : '50%'}>
                <MDBox pr={2}>
                  <Collapse in={aiCollapsed}>{searchResult.related_articles?.map((article) => relatedContentArticle(article))}</Collapse>
                </MDBox>
              </MDBox>
              {!highlighted && (
                <MDBox width={'50%'}>
                  <MDTypography
                    mb={2}
                    sx={({ palette: { grey }, typography: { size, fontWeightMedium } }: Theme) => ({
                      fontSize: size.lg,
                      fontWeight: fontWeightMedium,
                      color: grey[900],
                    })}
                  >
                    Related templates {`(${searchResult.related_templates?.length || 0})`}
                  </MDTypography>
                  <MDBox pr={2}>
                    <Collapse in={aiCollapsed}>{searchResult.related_templates?.map((template) => relatedContentTemplate(template))}</Collapse>
                  </MDBox>
                </MDBox>
              )}
            </Stack>
          )}
          {((!highlighted && searchResult.related_articles && searchResult.related_articles?.length > 2) ||
            (!highlighted && searchResult.related_templates && searchResult?.related_templates?.length > 2)) && (
            <>
              <MDBox
                mt={4}
                sx={{
                  color: 'primary.focus',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => setCollapsed(collapsed === '' ? searchResult.article_id : '')}
              >
                <MDTypography variant="caption1" color="">
                  {collapsed === searchResult.article_id ? 'Show less' : 'Show more'}
                </MDTypography>
                <Icon>{collapsed === searchResult.article_id ? 'expand_less' : 'expand_more'}</Icon>
              </MDBox>
            </>
          )}
          {((highlighted && searchResult.related_articles && searchResult.related_articles?.length > 1) ||
            (highlighted && searchResult.related_templates && searchResult?.related_templates?.length > 1)) && (
            <>
              {aiCollapsed && (
                <MDBox
                  my={1}
                  sx={{
                    color: 'primary.main',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <MDTypography
                    sx={({ palette: { grey }, typography: { size, fontWeightMedium } }: Theme) => ({
                      fontSize: size.sm,
                      fontWeight: fontWeightMedium,
                      color: grey[800],
                      '& .highlights': {
                        backgroundColor: '#FEF0C7',
                      },
                    })}
                    dangerouslySetInnerHTML={{
                      __html: searchResult.article_closing_remarks,
                    }}
                  />
                </MDBox>
              )}
              <MDBox
                mt={4}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                }}
                onClick={() => setAiCollapsed(!aiCollapsed)}
              >
                {!aiCollapsed && (
                  <MDTypography
                    sx={({ typography: { size, fontWeightMedium } }: Theme) => ({
                      fontSize: size.lg,
                      fontWeight: fontWeightMedium,
                      color: 'primary.focus',
                    })}
                  >
                    Related items {`(${searchResult.related_articles?.length || 0})`}
                  </MDTypography>
                )}
                <MDBox sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                  <MDTypography variant="caption1" sx={{ color: 'primary.focus' }}>
                    {aiCollapsed ? 'Hide related items' : 'Show related items'}
                  </MDTypography>
                  <Icon sx={{ color: 'primary.focus' }}>{aiCollapsed ? 'expand_less' : 'expand_more'}</Icon>
                </MDBox>
              </MDBox>
              <MDBox mt={3} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <a
                  href="/ai-assistant/"
                  onClick={(e) => {
                    e.preventDefault() // Prevent default link behavior
                    // Open the AI Assistant in a named window 'CompXai'
                    const compXaiWindow = window.open('/ai-assistant/', 'CompXai')
                    // Check if the window was successfully opened before calling focus
                    if (compXaiWindow) {
                      compXaiWindow.focus() // Optionally focus the window if it exists
                    } else {
                      console.log('Failed to open the window. Possibly blocked by a popup blocker.')
                    }
                  }}
                >
                  <MDButton variant="gradient" color="primary">
                    <MDBox
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: ({ palette: { white } }: Theme): string => white.main,
                        px: 2.5,
                        pt: '3px',
                        borderRadius: '50%',
                        height: '25px',
                        width: '25px',
                        opacity: 0.7,
                        border: '1px #1A73E8 solid',
                        mr: 2,
                      }}
                    >
                      <l-helix size="15" speed="2.5" color="#1A73E8"></l-helix>
                    </MDBox>
                    Chat with our AI Assistant
                  </MDButton>
                </a>
              </MDBox>
              {/* {!aiLoading && (
                <FadeIn visible={!aiLoading}>
                  <MDBox
                    p={2}
                    my={3}
                    mb={1}
                    sx={({ palette: { primary }, borders: { borderRadius } }: Theme) => ({
                      display: 'inline-flex',
                      alignItems: 'center',
                      background: `${primary.light} !important`,
                      borderRadius: borderRadius.md,
                      width: '100%',
                    })}
                  >
                    <MDBox
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: ({ palette: { white } }: Theme): string => white.main,
                        px: 2.5,
                        py: 1,
                        borderRadius: '50%',
                        height: '50px',
                        width: '50px',
                        opacity: 0.7,
                        border: '1px #1A73E8 solid',
                        mr: 2,
                      }}
                    >
                      <l-helix size="35" speed="2.5" color="#1A73E8"></l-helix>
                    </MDBox>
                    <FormControl variant="standard" sx={{ my: 4, width: '100%' }}>
                      <MDInput placeholder="Ask a follow up question to ComplyChat or refine your result..." />
                    </FormControl>
                  </MDBox>
                </FadeIn>
              )} */}
            </>
          )}
        </MDBox>
      </MDBox>
    )
  }

  return (
    <ModuleWrapper backgroundColor="grey">
      {loading && (
        <FadeIn visible={loading}>
          <Stack spacing={4} sx={{ alignItems: 'center' }}>
            <CircularProgress size={100} />
            <MDTypography
              variant="h6"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Loading results...
            </MDTypography>
          </Stack>
        </FadeIn>
      )}
      {!loading &&
        (!emptySearchFilters ||
          activeSearchTab.filterDocumentCollection.length > 0 ||
          activeSearchTab.filterDocuments.length > 0 ||
          activeSearchTab.searchTerm ||
          activeSearchTab.filterDate) && (
          <FadeIn>
            {aiLoading && (
              <FadeIn visible={aiLoading}>
                <MDBox
                  p={2}
                  my={1}
                  sx={({ palette: { primary }, borders: { borderRadius } }: Theme) => ({
                    display: 'inline-flex',
                    alignItems: 'center',
                    background: `${primary.light} !important`,
                    borderRadius: borderRadius.md,
                    width: '100%',
                  })}
                >
                  <MDBox
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: ({ palette: { white } }: Theme): string => white.main,
                      px: 2.5,
                      py: 1,
                      borderRadius: '50%',
                      height: '50px',
                      width: '50px',
                      opacity: 0.7,
                      border: '1px #1A73E8 solid',
                      mr: 2,
                    }}
                  >
                    <l-helix size="35" speed="2.5" color="#1A73E8"></l-helix>
                  </MDBox>
                  <MDTypography variant="textMD_SB">
                    <b>
                      Loading AI suggestion for <i>{searchTerm}...</i>
                    </b>
                  </MDTypography>
                </MDBox>
              </FadeIn>
            )}
            {!aiLoading && aiResult && (
              <FadeIn visible={!aiLoading}>
                <MDBox
                  my={1}
                  sx={{
                    width: '100%',
                  }}
                >
                  <MDBox my={3}>{searchResultsTemplate(aiResult, true)}</MDBox>
                </MDBox>
              </FadeIn>
            )}
            {((!emptySearchFilters && activeSearchTab.filterDocumentCollection.length > 0) ||
              activeSearchTab.filterDocuments.length > 0 ||
              activeSearchTab.searchTerm ||
              activeSearchTab.filterDate) && (
              <MDBox mb={3}>
                <MDTypography variant="textXL_SB">
                  {searchResultsCount} results found {searchTerm ? `with '${searchTerm}'` : ''}
                </MDTypography>
              </MDBox>
            )}
            <Stack spacing={5}>
              {(activeSearchTab.searchFilters ||
                activeSearchTab.filterDocumentCollection.length > 0 ||
                activeSearchTab.filterDocuments.length > 0 ||
                activeSearchTab.searchTerm ||
                activeSearchTab.filterDate) &&
                searchResultsData.map((searchResult) => searchResultsTemplate(searchResult))}
            </Stack>
            {(activeSearchTab.searchFilters ||
              activeSearchTab.filterDocumentCollection.length > 0 ||
              activeSearchTab.filterDocuments.length > 0 ||
              activeSearchTab.searchTerm ||
              activeSearchTab.filterDate) && (
              <MDBox
                mt={8}
                px={9}
                sx={{
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <MDButton
                  variant="outlined"
                  color="dark"
                  startIcon={<ArrowBackIcon />}
                  disabled={searchActivePage === 1}
                  onClick={(e) => handlePageChange(e, searchActivePage - 1)}
                >
                  Previous
                </MDButton>
                <Pagination
                  hideNextButton
                  hidePrevButton
                  count={Math.round(searchResultsCount / 10)}
                  renderItem={(item) => <PaginationItem {...item} />}
                  page={searchActivePage}
                  onChange={handlePageChange}
                />
                <MDButton
                  variant="outlined"
                  color="dark"
                  endIcon={<ArrowForwardIcon />}
                  disabled={searchActivePage === Math.round(searchResultsCount / 10) || searchResultsCount < 10}
                  onClick={(e) => handlePageChange(e, searchActivePage + 1)}
                >
                  Next
                </MDButton>
              </MDBox>
            )}
          </FadeIn>
        )}
    </ModuleWrapper>
  )
}

export default SearchResults
