// Types
import RecentSearchTermProps from './RecentSearch.type'

export const getRecentSearchData = async (): Promise<RecentSearchTermProps[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/entry/v1/searchterm/popular/platform`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return result
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}
