export const getConfirmReset = async (tokenCode: string, password: string) => {
  const credentials = {
    password: password,
    password_confirm: password,
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_API_LOGIN_URL}/reset/${tokenCode}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
    const result = await response.json()
    if (response.ok) {
      return result
    }
    if (!response.ok) await Promise.reject(result)
    return response
  } catch (error) {
    return error
  }
}
