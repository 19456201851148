// @ts-ignore
import Cookies from 'js-cookie'

export const postShareFeedback = async (location: string, feeedbackBody: string) => {
  const cookies = Cookies.get()
  const body = {
    page_url: location,
    feedback: feeedbackBody,
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/share/feedback`, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json', 'X-CSRF-Token': cookies['XSRF-TOKEN'] }),
      body: JSON.stringify(body),
    })
    const result = await response.json()
    if (response.ok) {
      return result
    }
    if (!response.ok) await Promise.reject(result)
    return response
  } catch (error) {
    return error
  }
}
