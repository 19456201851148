import colors from "assets/theme/base/colors";

const { white } = colors;

// types
type Types = any;

const pagination: Types = {
  styleOverrides: {
    root: {
      "& .Mui-selected": {
        backgroundColor: `${white.main} !important`,
      },
    },
  },
};

export default pagination;
