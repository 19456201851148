import { WidgetDataProps } from './AltEntryWidget.type'

export const getWidgetData = async (query: string): Promise<WidgetDataProps[]> => {
  const token = sessionStorage.getItem('altToken') || ''
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/widget/articles_by_datapoints?${query}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Authentication-Token': token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  //Unauthorized redirect
  if (response.status === 401) {
    console.log('navigate!')
    window.location.replace('/widget/login')
  }
  if (response.status === 400) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return result
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}
