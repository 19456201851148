import { useContext, useEffect, useState } from 'react'
import { isNil, omitBy } from 'lodash'

// @mui imports
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { Theme } from '@mui/material/styles/createTheme'

// compendiumX imports
import { CXIconArticle } from 'components/CXIcon/CXIcon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Functional
import { getWidgetData } from './AltEntryWidget.service'

// Context
import { ErrorContext } from 'context/ErrorContext'
import { WidgetDataProps } from './AltEntryWidget.type'

const AltEntryWidget = () => {
  // Params
  const queryParams = new URLSearchParams(window.location.search)
  const objectsFromUrl = {
    TaxonomyCode: queryParams.get('TaxonomyCode'),
    TemplateCode: queryParams.get('TemplateCode'),
    Xcode: queryParams.get('Xcode'),
    Ycode: queryParams.get('Ycode'),
    Zcode: queryParams.get('Zcode'),
  }
  const filledQueryObjects = omitBy(objectsFromUrl, isNil)
  const arrayForQuery = Object.entries(filledQueryObjects).map(([key, value]) => ({ key, value }))
  const arrayWithKeysAndValues = arrayForQuery.map((obj) => String(obj.key + '=' + obj.value))
  const queryForApi = arrayWithKeysAndValues.join('&')

  // Error handling
  const { setErrorMessage } = useContext(ErrorContext)

  //Module specific
  const [loading, setLoading] = useState(false)
  const [widgetData, setWidgetData] = useState<WidgetDataProps[]>()
  const [showMoreText, setShowMoreText] = useState<string>()

  const truncateString = (string: string) => {
    return string?.length > 250 ? string.slice(0, 250 - 1) + '...' : string
  }

  const truncateArticleTitle = (string: string) => {
    return string?.length > 50 ? string.slice(0, 50 - 1) + '...' : string
  }

  const truncateDocumentId = (string: string) => {
    return string?.length > 15 ? string.slice(0, 15 - 1) + '...' : string
  }

  // Get Data
  useEffect(() => {
    const fetchWidgetData = async (): Promise<void> => {
      setLoading(true)
      try {
        setWidgetData(await getWidgetData(queryForApi))
      } catch (error: any) {
        setErrorMessage(error)
      }
      setLoading(false)
    }
    if (queryForApi) {
      fetchWidgetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryForApi])

  // Set location on navigation
  useEffect(() => {
    sessionStorage.setItem('lastLocationWidget', String(window.location.pathname + window.location.search))
  }, [queryForApi])

  return (
    <Container maxWidth={'sm'} fixed sx={{ mt: '90px' }}>
      <Stack>
        {!loading && widgetData && (
          <Stack direction={'column'} spacing={6}>
            {widgetData.map((widgetItem) => (
              <MDBox>
                <MDBox mb={2}>
                  <Stack direction={'row'}>
                    {widgetItem.subdocument_longname && (
                      <>
                        <Tooltip title={widgetItem.subdocument_longname} placement="bottom">
                          <MDBox
                            py={1}
                            px={1}
                            sx={({ borders: { borderRadius } }: Theme) => ({
                              cursor: 'default',
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              backgroundColor: '#98A2B3',
                              color: 'white.main',
                              borderRadius: borderRadius.md,
                            })}
                          >
                            <CXIconArticle sx={{ mr: 1 }} />
                            <MDTypography variant="textSM_M" color="white">
                              {truncateDocumentId(widgetItem.subdocument_longname)}
                            </MDTypography>
                          </MDBox>
                        </Tooltip>
                        <MDBox px={1} pt={'5px'}>
                          <Icon>chevron_right</Icon>
                        </MDBox>
                      </>
                    )}
                    {widgetItem.article_toc_path_long_object && (
                      <Tooltip title={widgetItem.article_toc_path_long_object?.map((obj) => truncateDocumentId(obj?.toc_nr)).slice(-1)} placement="bottom">
                        <MDBox
                          py={1}
                          px={2}
                          sx={({ borders: { borderRadius } }: Theme) => ({
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#EAECF0',
                            borderRadius: borderRadius.md,
                          })}
                        >
                          <MDTypography variant="caption1" color={'dark'}>
                            {widgetItem.article_toc_path_long_object?.map((obj) => truncateDocumentId(obj?.toc_nr)).slice(-1)}
                          </MDTypography>
                        </MDBox>
                      </Tooltip>
                    )}
                    <MDBox px={1} pt={'5px'}>
                      <Icon>chevron_right</Icon>
                    </MDBox>
                    <Tooltip title={widgetItem.article_nr} placement="bottom">
                      <MDBox
                        py={'4px'}
                        px={'8px'}
                        sx={({ borders: { borderRadius } }: Theme) => ({
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: '#ECFDFF',
                          borderRadius: borderRadius.md,
                        })}
                        onClick={() => window.open(`/article/${widgetItem.document_id}/${widgetItem.article_anchor}`, '_blank', 'rel=noopener noreferrer')}
                      >
                        <CXIconArticle sx={{ mr: '9px' }} />
                        <MDTypography variant="caption1">{truncateDocumentId(widgetItem.article_nr)}</MDTypography>
                      </MDBox>
                    </Tooltip>
                  </Stack>
                </MDBox>
                <MDBox mb={2}>
                  <MDTypography variant="textSM_SB">
                    {widgetItem.article_nr}
                    {'   '}
                    {widgetItem.article_title}
                  </MDTypography>
                  <MDTypography
                    variant="textSM"
                    sx={({ palette: { grey } }: Theme) => ({
                      color: grey[600],
                      display: 'block',
                      mt: 1,
                    })}
                  >
                    {showMoreText === widgetItem.article_nr ? widgetItem.article_content : truncateString(widgetItem.article_content)}
                    {showMoreText !== widgetItem.article_nr && widgetItem.article_content?.length > 250 && (
                      <MDTypography variant="textSM" color="secondary" sx={{ display: 'inline-block', ml: 1 }} onClick={() => setShowMoreText(widgetItem.article_nr)}>
                        read more
                      </MDTypography>
                    )}
                  </MDTypography>
                </MDBox>
                {widgetItem.related_articles?.length > 0 && (
                  <MDBox mb={1}>
                    <MDTypography variant="textSM_SB">Related articles</MDTypography>
                    {widgetItem.related_articles.map((relatedArticle) => (
                      <MDTypography
                        variant="textSM"
                        color="secondary"
                        sx={{ cursor: 'pointer', display: 'block', my: 1, ml: 1 }}
                        onClick={() => window.open(`/article/${relatedArticle.document_id}/${relatedArticle.article_anchor}`, '_blank', 'rel=noopener noreferrer')}
                      >
                        - {relatedArticle.document_shortname}: {relatedArticle.article_nr}: {truncateArticleTitle(relatedArticle.article_title)}
                      </MDTypography>
                    ))}
                  </MDBox>
                )}
                {widgetItem.related_articles?.length === 0 && (
                  <MDBox mb={1}>
                    <MDTypography variant="textSM_SB">No related articles</MDTypography>
                  </MDBox>
                )}
                <Divider sx={{ mt: 8 }} />
              </MDBox>
            ))}
          </Stack>
        )}
      </Stack>
    </Container>
  )
}

export default AltEntryWidget
