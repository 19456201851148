import { useContext, useEffect, useState } from 'react'
import { chain, without } from 'lodash'
import FadeIn from 'global/fadein'

// mui imports
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import OutlinedInput from '@mui/material/OutlinedInput'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'

// compendiumX imports
import { CXIconColumnCode, CXIconRowCode, CXIconTaxonomyCode, CXIconTemplateCode } from 'components/CXIcon/CXIcon'
import CXDatePicker from 'components/CXDatePicker/CXDatePicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'

// Functional
import { getSearchFilters } from './SearchFilters.service'

// Types
import { AdditionalFilterProps, SearchFilterProps } from './SearchFilters.type'

// Context
import { ErrorContext } from 'context/ErrorContext'
import { SearchContext } from 'context/SearchContext'

const SearchFilters = () => {
  const { setErrorMessage } = useContext(ErrorContext)
  // Context
  const {
    searchTerm,
    searchFilters,
    filterDocumentCollection,
    filterDocuments,
    filterDate,
    activeSearchTab,
    getSetSearchFilters,
    getSetFilterDocumentCollection,
    getSetFilterDocuments,
    getSetFilterDate,
  } = useContext(SearchContext)

  //Module specific
  const [docFilterSearchTerm, setDocFilterSearchTerm] = useState('')
  const [additionalFiltersActive, setAdditionalFiltersActive] = useState(false)
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true)
  const [documentLongnamesOpen, openDocumentLongnames] = useState(false)

  //Data
  const additionalFilters: AdditionalFilterProps[] = [
    { name: 'Taxonomy code', icon: <CXIconTaxonomyCode />, apiName: 'relatedTaxonomyCodes' },
    { name: 'Template code', icon: <CXIconTemplateCode />, apiName: 'relatedTemplateCodes' },
    { name: 'Row code', icon: <CXIconRowCode />, apiName: 'relatedRows' },
    { name: 'Column code', icon: <CXIconColumnCode />, apiName: 'relatedColumns' },
  ]

  const [searchFilterData, setSearchFilterData] = useState<SearchFilterProps>()

  // Data
  useEffect(() => {
    const fetchSearchFiltersData = async (): Promise<void> => {
      setLoading(true)
      try {
        setSearchFilterData(await getSearchFilters(searchTerm || '*', searchFilters, filterDocuments, filterDocumentCollection, filterDate))
      } catch (error: any) {
        setErrorMessage(error)
      }
      setLoading(false)
    }
    fetchSearchFiltersData()
    // eslint-disable-next-line
  }, [searchTerm, searchFilters, filterDocuments, filterDocumentCollection, filterDate])

  useEffect(() => {
    const emptyValuesCheck = activeSearchTab.searchFilters ? Object.values(activeSearchTab.searchFilters).every((x) => x === null || x === '') : true
    if (!emptyValuesCheck) {
      setAdditionalFiltersActive(true)
    }
  }, [activeSearchTab])

  const truncateString = (string: string) => {
    return string.length > 30 ? string.slice(0, 30 - 1) + '...' : string
  }

  const additionalFiltersTemplate = (additionalFilter: AdditionalFilterProps) => {
    return (
      <MDBox mb={2}>
        <MDTypography
          sx={({ palette: { grey }, typography: { fontWeightMedium } }: Theme) => ({
            fontWeight: fontWeightMedium,
            color: grey[800],
          })}
        >
          {additionalFilter.name}
        </MDTypography>
        <FormControl variant="standard" sx={{ mt: 2, width: '100%' }}>
          <Autocomplete
            sx={{ width: '300' }}
            options={searchFilterData ? searchFilterData[additionalFilter.apiName].map((option) => option.value) : []}
            autoHighlight
            onChange={(event: any, newValue: string | null) => {
              getSetSearchFilters(additionalFilter.apiName, newValue)
            }}
            value={searchFilters ? searchFilters[additionalFilter.apiName] : null}
            renderInput={(params) => (
              <MDInput
                {...params}
                placeholder={`Select ${additionalFilter.name.toLowerCase()}`}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                  sx: { marginLeft: 6 },
                }}
                size="small"
              />
            )}
          />
          <Icon
            sx={({ palette: { grey } }: Theme) => ({
              fontSize: '1rem !important',
              position: 'absolute',
              top: 11,
              left: 12,
              color: grey[500],
            })}
          >
            {additionalFilter.icon}
          </Icon>
        </FormControl>
      </MDBox>
    )
  }

  const activeFilterTemplate = (value: string, type: 'documentCollection' | 'documents') => {
    return (
      <Chip
        label={value}
        onDelete={() =>
          type === 'documentCollection' ? getSetFilterDocumentCollection(without(filterDocumentCollection, value)) : getSetFilterDocuments(without(filterDocuments, value))
        }
        deleteIcon={
          <Icon
            sx={({ palette: { indigo } }: Theme) => ({
              color: `${indigo.additional} !important`,
            })}
          >
            clear
          </Icon>
        }
        sx={({ palette: { indigo }, typography: { size } }: Theme) => ({
          backgroundColor: indigo.main,
          fontSize: size.sm,
          color: indigo.additional,
        })}
      />
    )
  }

  // Grouping document collections - groups are now created based on the availability of ' - ' in the value
  const groupedDocumentCollections = searchFilterData?.documentCollections?.map((documentCollection) => {
    if (documentCollection.value.includes(' - ')) {
      const groupedDocumentCollection = {
        ...documentCollection,
        checked: filterDocumentCollection.includes(documentCollection.value),
        group: documentCollection.value.split(' - ')[0],
        groupValue: documentCollection.value.split(' - ')[1],
      }
      return groupedDocumentCollection
    }
    return documentCollection
  })
  const groupedByCollections = chain(groupedDocumentCollections?.filter((documentCollection) => documentCollection.group))
    .groupBy('group')
    .map((value, key) => ({ group: key, collections: value }))
    .value()

  return (
    <MDBox py={8} pl={1} pr={8} sx={{ minWidth: '400px' }}>
      {loading ? (
        <FadeIn visible={loading}>
          <Stack spacing={4} sx={{ alignItems: 'center' }}>
            <CircularProgress size={100} />
            <MDTypography
              variant="h6"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Loading filters...
            </MDTypography>
          </Stack>
        </FadeIn>
      ) : (
        <FadeIn visible={!loading}>
          <MDBox
            mb={'48px'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <MDTypography
              variant="textXL_SB"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[800],
              })}
            >
              Filter by template
            </MDTypography>
            {!additionalFiltersActive && <Switch checked={additionalFiltersActive} onClick={() => setAdditionalFiltersActive(true)} />}
            {additionalFiltersActive && <Switch checked={additionalFiltersActive} onClick={() => setAdditionalFiltersActive(false)} />}
          </MDBox>
          <Collapse in={additionalFiltersActive}>
            <Stack mb={4}>{additionalFilters.map((additionalFilter) => additionalFiltersTemplate(additionalFilter))}</Stack>
          </Collapse>
          <MDBox mb={2}>
            <MDTypography
              variant="textLG_M"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[800],
              })}
            >
              Collections
            </MDTypography>
          </MDBox>
          {filterDocumentCollection.length > 0 && (
            <MDBox sx={{ display: 'flex', flexWrap: 'wrap' }} mb={3}>
              {filterDocumentCollection?.map((collection) => activeFilterTemplate(collection, 'documentCollection'))}
            </MDBox>
          )}
          {/* Grouped document collections */}
          {groupedByCollections.map((groupedCollection) => {
            return (
              <>
                <FormControlLabel
                  label={`${groupedCollection?.group} (${groupedCollection.collections.map((collection) => collection.count).reduce((a, b) => a + b)})`}
                  sx={{ ml: 1 }}
                  control={
                    <MDBox />
                    // <Checkbox
                    //   checked={groupedCollection.collections.filter((collection) => !collection.checked).length === 0}
                    //   indeterminate={groupedCollection.collections.filter((collection) => collection.checked).length > 0}
                    //   onChange={() =>
                    //     groupedCollection.collections.filter((collection) => collection.checked).length !== groupedCollection.collections.length
                    //       ? getSetFilterDocumentCollection([...filterDocumentCollection, groupedCollection.collections.map((collection) => collection.value)])
                    //       : getSetFilterDocumentCollection([])
                    //   }
                    // />
                  }
                />
                {groupedCollection.collections.map((collection) => {
                  return (
                    <MDBox sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      <FormControlLabel
                        label={`${collection?.groupValue} (${collection.count})`}
                        sx={{ mb: 1 }}
                        control={
                          <Checkbox
                            checked={filterDocumentCollection.includes(collection.value)}
                            onChange={() =>
                              filterDocumentCollection.includes(collection.value)
                                ? getSetFilterDocumentCollection(without(filterDocumentCollection, collection.value))
                                : getSetFilterDocumentCollection([...filterDocumentCollection, collection.value])
                            }
                            disabled={collection.count === 0}
                          />
                        }
                      />
                    </MDBox>
                  )
                })}
              </>
            )
          })}
          {/* Ungrouped document collections */}
          {groupedDocumentCollections
            ?.filter((groupedCollection) => !groupedCollection.group)
            .map((documentCollection) => {
              return (
                <FormControlLabel
                  // eslint-disable-next-line
                  label={`${documentCollection.value} (${documentCollection.count})`}
                  sx={{ mb: 1 }}
                  control={
                    <Checkbox
                      checked={filterDocumentCollection.includes(documentCollection.value)}
                      onChange={() =>
                        filterDocumentCollection.includes(documentCollection.value)
                          ? getSetFilterDocumentCollection(without(filterDocumentCollection, documentCollection.value))
                          : getSetFilterDocumentCollection([...filterDocumentCollection, documentCollection.value])
                      }
                      disabled={documentCollection.count === 0}
                    />
                  }
                />
              )
            })}
          <MDBox my={2}>
            <MDTypography
              variant="textLG_M"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[800],
              })}
            >
              Documents
            </MDTypography>
          </MDBox>
          <MDBox mb={3}>
            <OutlinedInput
              placeholder="Search"
              value={docFilterSearchTerm}
              onChange={(e) => setDocFilterSearchTerm(e.target.value)}
              startAdornment={<Icon>search</Icon>}
              sx={{ height: '40px', width: '100%' }}
            />
          </MDBox>
          {filterDocuments.length > 0 && (
            <MDBox sx={{ display: 'flex', flexWrap: 'wrap' }} mb={3}>
              {filterDocuments.map((document) => activeFilterTemplate(document, 'documents'))}
            </MDBox>
          )}
          {searchFilterData?.documentLongnames
            ?.slice(0, documentLongnamesOpen ? searchFilterData?.documentLongnames.length : 6)
            ?.filter((filter) => filter.value.toLowerCase().includes(docFilterSearchTerm.toLocaleLowerCase()))
            .map((documentLongName) => {
              return (
                <FormControlLabel
                  // eslint-disable-next-line
                  label={`${truncateString(documentLongName.value)} (${documentLongName.count})`}
                  sx={{ mb: 1 }}
                  control={
                    <Checkbox
                      checked={filterDocuments.includes(documentLongName.value)}
                      onChange={() =>
                        filterDocuments.includes(documentLongName.value)
                          ? getSetFilterDocuments(without(filterDocuments, documentLongName.value))
                          : getSetFilterDocuments([...filterDocuments, documentLongName.value])
                      }
                      disabled={documentLongName.count === 0}
                    />
                  }
                />
              )
            })}
          {searchFilterData?.documentLongnames && searchFilterData?.documentLongnames.length > 6 && (
            <MDButton
              variant="text"
              size="small"
              sx={{
                pl: 0,
                color: 'primary.focus',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
              onClick={() => openDocumentLongnames(!documentLongnamesOpen)}
            >
              {!documentLongnamesOpen ? `Show ${searchFilterData?.documentLongnames.length - 6} more` : 'Show less results'}
            </MDButton>
          )}
          {/* DATE FILTER */}
          <MDBox mt={2} mb={10}>
            <MDTypography
              variant="textLG_M"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[800],
              })}
            >
              Published date
            </MDTypography>
            <MDBox>
              <CXDatePicker value={filterDate} onChange={(dateValue) => getSetFilterDate(dateValue)} />
            </MDBox>
          </MDBox>
        </FadeIn>
      )}
    </MDBox>
  )
}
export default SearchFilters
