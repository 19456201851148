import { createContext, useCallback, useEffect, useState } from 'react'

export interface ErrorContextProps {
  errorOpen: boolean
  errorMessage: string
  setErrorOpen: any
  setErrorMessage: any
}

export const ErrorContext = createContext({} as ErrorContextProps)

export const ErrorProvider = ({ children }: any) => {
  const [errorOpen, setErrorOpen] = useState(false)
  const [errorMessage, setErrorMessageActive] = useState('')
  const standardErrorText = 'Please refresh the page. If the error persists, please contact CompendiumX support - support@compendium-x.com.'

  const setErrorMessage = useCallback(
    (message: string) => {
      if (!errorOpen && message > '') {
        setErrorOpen(true)
        setErrorMessageActive(String(message + standardErrorText))
      }
    },
    [errorOpen]
  )

  useEffect(() => {
    if (!errorOpen) {
      setErrorMessage('')
    }
  }, [errorOpen, setErrorMessage, errorMessage])

  return (
    <div>
      <ErrorContext.Provider value={{ errorOpen, errorMessage, setErrorOpen, setErrorMessage }}>{children}</ErrorContext.Provider>
    </div>
  )
}
