import { useContext, useEffect, useState } from 'react'
import FadeIn from 'global/fadein'
import './DocumentScreen.css'

// MUI imports
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'

// CompendiumX components
import MDTypography from 'components/MDTypography'

// Context
import { ErrorContext } from 'context/ErrorContext'

// Functional
import { getDocumentContent } from './DocumentScreen.service'

interface Props {
  documentId: string
  anchor?: string
  setAnchor: (anchor: string) => void
  documentScrolled: boolean
  setDocumentScrolled: (scrolled: boolean) => void
  adminMode?: boolean
}

const DocumentScreen: React.FC<Props> = (props) => {
  const { documentId, anchor, setAnchor, documentScrolled, setDocumentScrolled, adminMode } = props
  //Data
  const [loading, setLoading] = useState(false)
  const [documentContent, setDocumentContent] = useState<any>()
  // Error handling
  const { setErrorMessage } = useContext(ErrorContext)

  useEffect(() => {
    const fetchDocumentContent = async (): Promise<void> => {
      setLoading(true)
      try {
        setDocumentContent(await getDocumentContent(documentId))
        setLoading(false)
      } catch (error: any) {
        setErrorMessage(error)
        setLoading(false)
      }
    }
    fetchDocumentContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function css(element: any, style: any) {
    for (const property in style) element.style[property] = style[property]
  }

  useEffect(() => {
    async function fetchHighlight() {
      const compxlinks = window.document?.querySelectorAll(`[id^="compxlink"]`)
      compxlinks.forEach((el) => el.classList.add('compx'))
      compxlinks.forEach((box: any, i) => {
        css(box, {
          cursor: 'pointer',
          backgroundColor: box.id === anchor ? '#FEF0C7' : adminMode ? '#F0FEC7' : '',
        })
        box.onclick = () => {
          setAnchor(box.id)
        }
      })
      const foundId = anchor ? window.document?.getElementById(anchor) : ''
      const repeatFoundId = anchor ? window.document?.getElementById(anchor) : ''

      if (foundId) {
        setTimeout(() => {
          foundId.scrollIntoView({
            block: 'center',
            inline: 'center',
            behavior: 'smooth',
          })
          if (!documentScrolled) {
            setTimeout(() => {
              setDocumentScrolled(true)
            }, 1000)
          }
        }, 155)
      }
      if (repeatFoundId) {
        setTimeout(() => {
          repeatFoundId.scrollIntoView({
            block: 'center',
            inline: 'center',
            behavior: 'smooth',
          })
        }, 1000)
      } else {
        setDocumentScrolled(true)
      }
    }
    if (documentContent) {
      fetchHighlight()
    }
    if (!anchor) {
      setDocumentScrolled(true)
    }
    // eslint-disable-next-line
  }, [documentContent, loading, anchor, adminMode])

  const createMarkup = (data: any) => {
    return { __html: data }
  }

  if (loading)
    return (
      <Box>
        <Stack spacing={3} sx={{ alignItems: 'center' }}>
          <CircularProgress size={100} />
          <MDTypography variant="textMD">Loading document content ...</MDTypography>
        </Stack>
      </Box>
    )
  return (
    <FadeIn visible={!loading} delay={50}>
      <style>
        {`
          #document_view a {
            display: none;
          }
        `}
      </style>
      <div
        id="document_view"
        style={{
          backgroundColor: 'white',
          height: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingLeft: '50px',
          paddingRight: '50px',
          marginRight: 0,
          transition: 'width 1s ease-in-out',
        }}
        dangerouslySetInnerHTML={createMarkup(documentContent)}
      />
    </FadeIn>
  )
}

export default DocumentScreen
