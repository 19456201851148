import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { debounce } from 'lodash'

// mui imports
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import Stack from '@mui/system/Stack'
import { Theme } from '@mui/material/styles/createTheme'

// compendiumX imports
import { CXIconArticle, CXIconCalendar, CXIconClock, CXIconRelevancy } from 'components/CXIcon/CXIcon'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'

// Functional
import { boldString } from './SearchContainer.helpers'
import { getPopularSearchTerms, getSearchSuggestions } from './SearchContainer.service'

// Context
import { ErrorContext } from 'context/ErrorContext'
import { SearchContext } from 'context/SearchContext'

// Types
import { SearchPopularSearchTermProps, SearchSuggestionProps } from './SearchContainer.type'

const SearchContainer = () => {
  const { setErrorMessage } = useContext(ErrorContext)
  const truncateString = (string: string) => {
    return string.length > 30 ? string.slice(0, 30 - 1) + '...' : string
  }
  // Context
  const { searchTerm, activeSearchTab, searchSorter, getSetSearchTerm, getSetSearchSorter } = useContext(SearchContext)

  // Module specific
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm)
  const handleChangeSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    setLocalSearchTerm(event.target.value)
  }

  // Module specific initial
  const [initialSearchState, setInitialSearchState] = useState(true)
  const [initialSearchTerm, setInitialSearchTerm] = useState<string | undefined>()
  const handleChangeInitialSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
    setInitialSearchTerm(event.target.value)
  }
  const finishInitialSearch = () => {
    getSetSearchTerm(initialSearchTerm)
    setInitialSearchState(false)
  }

  const emptySearchFilters = activeSearchTab.searchFilters && Object.values(activeSearchTab?.searchFilters).every((value) => value === null)

  useEffect(() => {
    if (activeSearchTab.count) {
      setInitialSearchState(false)
    }
    if (!activeSearchTab.active) {
      setInitialSearchState(true)
    }
    if (
      (emptySearchFilters || emptySearchFilters === undefined) &&
      activeSearchTab.filterDocumentCollection.length === 0 &&
      activeSearchTab.filterDocuments.length === 0 &&
      activeSearchTab.searchTerm === '' &&
      !activeSearchTab.filterDate
    ) {
      setInitialSearchState(true)
    }
    // eslint-disable-next-line
  }, [activeSearchTab])

  // Popular Search Terms
  // eslint-disable-next-line
  const [popularSearchTermsLoading, setPopularSearchTermsLoading] = useState(false)
  const [popularSearchTerms, setPopularSearchTerms] = useState<SearchPopularSearchTermProps[]>()

  useEffect(() => {
    const fetchPopularSearchTerms = async (): Promise<void> => {
      setPopularSearchTermsLoading(true)
      try {
        const popularSearchTerms = await getPopularSearchTerms()
        setPopularSearchTerms(popularSearchTerms)
      } catch (error: any) {
        setErrorMessage(error)
      }
      setPopularSearchTermsLoading(false)
    }
    fetchPopularSearchTerms()
    // eslint-disable-next-line
  }, [])

  // Sort menu specific
  const [sortMenuOpen, setSortMenuOpen] = useState(false)
  const [sortOptions, setSortOptions] = useState([
    {
      name: 'Date',
      value: 'document_date',
      icon: <CXIconCalendar />,
      active: true,
    },
    {
      name: 'Document',
      value: 'document_name',
      icon: <CXIconArticle />,
      active: false,
    },
    {
      name: 'Relevancy',
      value: 'search.score()',
      icon: <CXIconRelevancy />,
      active: false,
    },
  ])

  const handleMenuItemClick = (value: string) => {
    const activeMenuOptions = sortOptions.map((sortOption) => {
      return sortOption.value === value ? { ...sortOption, active: true } : { ...sortOption, active: false }
    })
    setSortMenuOpen(false)
    getSetSearchSorter(value)
    setSortOptions(activeMenuOptions)
  }
  const actveOption = sortOptions.filter((option) => option.active)[0]

  useEffect(() => {
    setLocalSearchTerm(activeSearchTab.searchTerm)
    if (searchSorter) {
      const activeMenuOptions = sortOptions.map((sortOption) => {
        return sortOption.value === searchSorter ? { ...sortOption, active: true } : { ...sortOption, active: false }
      })
      setSortOptions(activeMenuOptions)
    }
    // eslint-disable-next-line
  }, [activeSearchTab.searchTerm, searchSorter])

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        getSetSearchTerm(localSearchTerm)
        handleClose()
      }
    }

    document.addEventListener('keydown', keyDownHandler)

    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
    // eslint-disable-next-line
  }, [localSearchTerm])

  // Search suggestions
  const [suggestionsLoading, setSuggestionsLoading] = useState(false)
  const [searchSuggestions, setSearchSuggestions] = useState<{
    autocomplete: SearchSuggestionProps[]
    suggestion: SearchSuggestionProps[]
    recent_searchterms: SearchSuggestionProps[]
  }>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    // Define debounce functions with a delay (e.g., 300 milliseconds)
    const debouncedGetSuggestions = debounce(async () => {
      if (initialSearchTerm || open) {
        setSuggestionsLoading(true)
        try {
          const searchSuggestions = await getSearchSuggestions(localSearchTerm || initialSearchTerm)
          setSearchSuggestions(searchSuggestions)
        } catch (error: any) {
          setErrorMessage(error)
        }
        setSuggestionsLoading(false)
      }
    }, 300)

    // Call the debounced functions instead
    debouncedGetSuggestions()

    // Cleanup function to cancel debounced functions on unmount
    return () => {
      debouncedGetSuggestions.cancel()
    }

    // eslint-disable-next-line
  }, [localSearchTerm, initialSearchTerm, open])

  const selectSuggestion = (value: string) => {
    setLocalSearchTerm(value)
    getSetSearchTerm(value)
    handleClose()
  }

  return (
    <MDBox
      sx={{
        display: initialSearchState ? 'block' : 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <MDBox>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Home
          </Link>
          <Link underline="hover" color="inherit" href="/search">
            Search
          </Link>
          {activeSearchTab.searchTerm && (
            <MDBox
              sx={{
                borderRadius: '8px',
                backgroundColor: 'white.main',
                p: 2,
                display: 'flex',
              }}
            >
              <MDTypography color="text.primary" variant="textSM_M" sx={{ lineHeight: 0, p: 1 }}>
                {truncateString(activeSearchTab.searchTerm)}
              </MDTypography>
            </MDBox>
          )}
        </Breadcrumbs>
      </MDBox>
      {!initialSearchState && (
        <MDBox sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <ClickAwayListener onClickAway={handleClose}>
            <FormControl variant="standard" sx={{ position: 'relative' }}>
              <MDInput
                placeholder="Search across compendium"
                variant="veryRounded"
                onChange={handleChangeSearchTerm}
                onClickAway={handleChangeSearchTerm}
                value={localSearchTerm}
                onClick={!open ? handleClick : null}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                  className: open && 'Mui-focused',
                }}
              />
              {open && (
                <MDBox
                  sx={({ borders }: Theme) => ({
                    backgroundColor: 'white !important',
                    position: 'absolute',
                    top: '35px',
                    width: '300px',
                    border: `1px solid ${borders.borderColor}`,
                    borderBottomRightRadius: '0.625rem',
                    borderBottomLeftRadius: '0.625rem',
                    zIndex: 2,
                  })}
                >
                  <Stack spacing={1} divider={<Divider orientation="horizontal" sx={{ marginLeft: '-16px !important', width: '227px' }} />} sx={{ px: 4, py: 1 }}>
                    <MDBox pb={1}>
                      <MDTypography variant="textXS_M">
                        <b>Search suggestion</b>
                      </MDTypography>
                      {suggestionsLoading && (
                        <MDBox sx={{ display: 'flex', alignItems: 'center' }}>
                          <CircularProgress size={20} sx={{ mr: 1 }} />
                          <MDTypography variant="textXS">Loading suggestions ...</MDTypography>
                        </MDBox>
                      )}
                      {searchSuggestions?.autocomplete.map((autoComplete) => (
                        <MDBox
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => selectSuggestion(autoComplete.queryPlusText)}
                          my={3}
                        >
                          <CXIconArticle sx={{ width: '12px', mr: 2 }} />
                          <MDTypography
                            variant="textSM"
                            dangerouslySetInnerHTML={{
                              __html: boldString(autoComplete.queryPlusText, localSearchTerm || ''),
                            }}
                          />
                        </MDBox>
                      ))}
                      {!suggestionsLoading &&
                        searchSuggestions?.suggestion.map((searchSuggestion) => (
                          <MDBox
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => selectSuggestion(searchSuggestion.queryPlusText)}
                            my={3}
                          >
                            <CXIconArticle sx={{ width: '12px', mr: 2 }} />
                            <MDTypography
                              variant="textSM"
                              dangerouslySetInnerHTML={{
                                __html: boldString(searchSuggestion.queryPlusText, localSearchTerm || ''),
                              }}
                            />
                          </MDBox>
                        ))}
                    </MDBox>
                    <MDBox>
                      <MDBox>
                        <MDTypography variant="textXS_M">
                          <b>Recent searches</b>
                        </MDTypography>
                      </MDBox>
                      {suggestionsLoading && (
                        <MDBox sx={{ display: 'inline-flex', alignItems: 'center' }}>
                          <CircularProgress size={20} sx={{ mr: 1 }} />
                          <MDTypography variant="textXS">Loading searches ...</MDTypography>
                        </MDBox>
                      )}
                      {!suggestionsLoading &&
                        searchSuggestions?.recent_searchterms.map((recentSearchTerm) => (
                          <MDBox
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => selectSuggestion(recentSearchTerm.queryPlusText)}
                            my={3}
                          >
                            <CXIconClock sx={{ width: '12px', mr: 2 }} />
                            <MDTypography variant="textSM">{recentSearchTerm.queryPlusText}</MDTypography>
                          </MDBox>
                        ))}
                    </MDBox>
                  </Stack>
                </MDBox>
              )}
            </FormControl>
          </ClickAwayListener>
          <MDTypography variant="textMD_M" mx={5}>
            Sort by
          </MDTypography>
          <MDBox>
            <FormControl sx={{ mt: 2, mb: 2, width: '200px' }}>
              <Select
                displayEmpty
                input={<OutlinedInput />}
                open={sortMenuOpen}
                onOpen={() => setSortMenuOpen(true)}
                onClose={() => setSortMenuOpen(true)}
                MenuProps={{
                  onClick: (e) => {
                    e.preventDefault()
                    setSortMenuOpen(false)
                  },
                }}
                renderValue={() => {
                  return (
                    <MDBox
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '40px',
                      }}
                    >
                      <Icon>{actveOption.icon}</Icon>
                      <MDTypography variant="textMD_M" ml={'8px'}>
                        {actveOption.name}
                      </MDTypography>
                      <Icon sx={{ ml: 'auto' }}>expand_more</Icon>
                    </MDBox>
                  )
                }}
              >
                <MenuList>
                  {sortOptions.map((option, index) => (
                    <MenuItem key={index} value={option.value} onClick={() => handleMenuItemClick(option.value)}>
                      {option.icon}
                      <MDTypography variant="textMD_M" ml={'8px'}>
                        {option.name}
                      </MDTypography>
                    </MenuItem>
                  ))}
                </MenuList>
              </Select>
            </FormControl>
          </MDBox>
        </MDBox>
      )}
      {initialSearchState && (
        <MDBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack>
            <MDBox sx={{ mb: 8 }} mx={'auto'}>
              <MDTypography variant="textXL_SB" sx={{ textAlign: 'justify' }} px={'90px'} width="440px">
                Search among collection of 28000+ articles
              </MDTypography>
            </MDBox>
            <MDBox mx={'auto'}>
              <ClickAwayListener onClickAway={handleClose}>
                <FormControl variant="standard" sx={{ position: 'relative' }}>
                  <MDInput
                    placeholder="Search across compendium ..."
                    variant="veryRoundedWide"
                    onChange={handleChangeInitialSearchTerm}
                    onClickAway={handleChangeInitialSearchTerm}
                    value={initialSearchTerm}
                    onClick={!open ? handleClick : null}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>search</Icon>
                        </InputAdornment>
                      ),
                      className: open && 'Mui-focused',
                    }}
                  />
                  {open && (
                    <MDBox
                      sx={({ borders }: Theme) => ({
                        backgroundColor: 'white !important',
                        position: 'absolute',
                        top: '35px',
                        width: '360px',
                        border: `1px solid ${borders.borderColor}`,
                        borderBottomRightRadius: '0.625rem',
                        borderBottomLeftRadius: '0.625rem',
                        zIndex: 2,
                      })}
                    >
                      <Stack
                        spacing={1}
                        divider={
                          <Divider
                            orientation="horizontal"
                            sx={{
                              marginLeft: '-16px !important',
                              width: '360px',
                            }}
                          />
                        }
                        sx={{ px: 4, py: 1 }}
                      >
                        <MDBox pb={'6px'}>
                          <MDTypography variant="textXS_M">
                            <b>Search suggestion</b>
                          </MDTypography>
                          {suggestionsLoading && (
                            <MDBox
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <CircularProgress size={20} sx={{ mr: 1 }} />
                              <MDTypography variant="textXS_M">Loading suggestions ...</MDTypography>
                            </MDBox>
                          )}
                          {searchSuggestions?.autocomplete.map((autoComplete) => (
                            <MDBox
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                              onClick={() => selectSuggestion(autoComplete.queryPlusText)}
                              my={3}
                            >
                              <CXIconArticle sx={{ width: '12px', mr: 2 }} />
                              <MDTypography
                                variant="textSM"
                                dangerouslySetInnerHTML={{
                                  __html: boldString(autoComplete.queryPlusText, initialSearchTerm || ''),
                                }}
                              />
                            </MDBox>
                          ))}
                          {!suggestionsLoading &&
                            searchSuggestions?.suggestion.map((searchSuggestion) => (
                              <MDBox
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                }}
                                onClick={() => selectSuggestion(searchSuggestion.queryPlusText)}
                                my={3}
                              >
                                <CXIconArticle sx={{ width: '12px', mr: 2 }} />
                                <MDTypography
                                  variant="textSM"
                                  dangerouslySetInnerHTML={{
                                    __html: boldString(searchSuggestion.queryPlusText, initialSearchTerm || ''),
                                  }}
                                />
                              </MDBox>
                            ))}
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="textXS_M">
                            <b>Recent searches</b>
                          </MDTypography>
                          {suggestionsLoading && (
                            <MDBox
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <CircularProgress size={20} sx={{ mr: 1 }} />
                              <MDTypography variant="textXS_M">Loading searches ...</MDTypography>
                            </MDBox>
                          )}
                          {!suggestionsLoading &&
                            searchSuggestions?.recent_searchterms.map((recentSearchTerm) => (
                              <MDBox
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                }}
                                onClick={() => selectSuggestion(recentSearchTerm.queryPlusText)}
                                my={3}
                              >
                                <CXIconClock sx={{ width: '12px', mr: 2 }} />
                                <MDTypography variant="textSM">{recentSearchTerm.queryPlusText}</MDTypography>
                              </MDBox>
                            ))}
                        </MDBox>
                      </Stack>
                    </MDBox>
                  )}
                </FormControl>
              </ClickAwayListener>
              <MDButton type="submit" variant="gradient" color="primary" size="large" onClick={() => finishInitialSearch()} sx={{ ml: 4 }}>
                Search
              </MDButton>
            </MDBox>
            <MDBox mt={5}>
              {popularSearchTerms?.map((popularTerm) => (
                <Chip
                  label={popularTerm.searchterm}
                  icon={
                    <Icon
                      sx={({ palette: { indigo } }: Theme) => ({
                        color: `${indigo.additional} !important`,
                      })}
                    >
                      search
                    </Icon>
                  }
                  onClick={() => selectSuggestion(popularTerm.searchterm)}
                  sx={({ palette: { indigo }, typography: { size } }: Theme) => ({
                    backgroundColor: indigo.main,
                    fontSize: size.sm,
                    color: indigo.additional,
                  })}
                />
              ))}
            </MDBox>
          </Stack>
        </MDBox>
      )}
    </MDBox>
  )
}
export default SearchContainer
