import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// MUI imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import OutlinedInput from '@mui/material/OutlinedInput'
import { Theme } from '@mui/material/styles/createTheme'

// CompendiumX components
import { CXIconArrowRightUp } from 'components/CXIcon/CXIcon'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// Context
import { ErrorContext } from 'context/ErrorContext'

// Functional
import { getDocumentDates, getDocumentInfo } from './DocumentInfoPanel.service'

interface Props {
  documentId: string
}

const DocumentInfoPanel: React.FC<Props> = (props) => {
  const { documentId } = props

  // History
  const navigate = useNavigate()
  const goToPreviousPath = () => {
    //   setComparisonPanel(false)
    navigate(-1)
  }
  //Data
  const [loading, setLoading] = useState(false)
  const [documentInfo, setDocumentInfo] = useState<any>()
  const [documentDates, setDocumentDates] = useState<any>()
  const [dateMenuOpen, setDateMenuOpen] = useState(false)
  // Error handling
  const { setErrorMessage } = useContext(ErrorContext)

  useEffect(() => {
    const fetchDocumentInfo = async (): Promise<void> => {
      setLoading(true)
      try {
        setDocumentInfo(await getDocumentInfo(documentId))
        setDocumentDates(await getDocumentDates(documentId))
        setLoading(false)
      } catch (error: any) {
        setErrorMessage(error)
        setLoading(false)
      }
    }
    fetchDocumentInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box
        height="72px"
        width="100%"
        py={2}
        px={8}
        sx={({ palette: { white, grey } }: Theme) => ({
          backgroundColor: white.main,
          border: `1px solid ${grey[300]}`,
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          zIndex: 9,
          left: 0,
        })}
      >
        <MDButton color="dark" variant="outlined" startIcon={<ArrowBackIcon />} onClick={goToPreviousPath}>
          Back
        </MDButton>
        <Box ml={5}>
          <Stack direction="column">
            <MDTypography variant="textXL_M" color="grey[900]">
              {!loading && `${documentInfo?.document_longname}`}
              {loading && (
                <Stack spacing={3} sx={{ alignItems: 'center' }} direction={'row'}>
                  <CircularProgress size={30} />
                  <span>Loading document information ...</span>
                </Stack>
              )}
            </MDTypography>
            <MDTypography variant="textSM" color="grey[700]">
              {!loading && `${documentInfo?.document_collection}: ${documentInfo?.document_version}`}
            </MDTypography>
          </Stack>
        </Box>
        {!loading && (
          <Box sx={{ ml: 'auto' }}>
            {documentDates && (
              <Select
                displayEmpty
                input={
                  <OutlinedInput
                    sx={{
                      '& legend': {
                        display: 'none',
                      },
                      pb: '5px !important',
                    }}
                  />
                }
                open={dateMenuOpen}
                onOpen={() => setDateMenuOpen(true)}
                onClose={() => setDateMenuOpen(false)}
                MenuProps={{
                  onClick: (e) => {
                    setDateMenuOpen(false)
                  },
                }}
                renderValue={() => {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '30px',
                      }}
                    >
                      <MDTypography variant="textMD_M">{documentDates?.filter((dateFilter: any) => dateFilter.is_selected === 1)[0].document_date}</MDTypography>
                      <Icon sx={{ ml: 'auto' }}>expand_more</Icon>
                    </Box>
                  )
                }}
              >
                <MenuList>
                  {documentDates?.map((dateFilter: any, index: number) => (
                    <MenuItem
                      key={index}
                      value={dateFilter.document_date}
                      onClick={() => {
                        window.location.replace(`/article/${dateFilter.document_id}`)
                      }}
                    >
                      <MDTypography variant="textMD_M">{dateFilter.document_date}</MDTypography>
                    </MenuItem>
                  ))}
                </MenuList>
              </Select>
            )}
            <MDButton
              href={documentInfo?.document_external_url}
              target="_blank"
              rel="noopener noreferrer"
              variant="text"
              sx={{
                ml: 'auto',
                color: 'primary.focus',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              Original document
              <CXIconArrowRightUp sx={{ ml: 2, height: '10px', width: '10px' }} />
            </MDButton>
          </Box>
        )}
      </Box>
    </>
  )
}

export default DocumentInfoPanel
