import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'; 
import { helix } from 'ldrs'

// images
import logo from 'img/logoTop.png'
import avatar_temp from 'img/avatars/avatartemp.jpeg'

// mui imports
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { Theme } from '@mui/material/styles/createTheme'
import Toolbar from '@mui/material/Toolbar'

// compendiumX imports
import { CXIconArticle, CXIconHelp, CXIconHome, CXIconLogout, CXIconMyProfile, CXIconPassword } from 'components/CXIcon/CXIcon'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// Context
import { UserContext } from 'context/UserContext'

// Types
import TopBarProps from './TopBar.type'

function TopBar({ profileOnly }: TopBarProps): JSX.Element {
  const navigate = useNavigate()
  helix.register()

  const { logoutUser, userName, userSSO, userRoles, adminPanel, toggleAdminPanel } = useContext(UserContext)
  const adminAccess = userRoles && userRoles?.indexOf('admin') > -1
  const aiAccess = userRoles && userRoles?.indexOf('ai_assistant') > -1

  const menuOptions = [
    {
      label: 'My profile',
      action: () => {
        navigate('/profile')
        handleCloseUserMenu()
      },
      icon: <CXIconMyProfile />,
      invisibile: profileOnly || !adminAccess,
    },
    {
      label: 'Change password',
      action: () => {
        navigate('/changePassword')
        handleCloseUserMenu()
      },
      icon: <CXIconPassword />,
      invisibile: profileOnly || userSSO,
    },
    {
      label: 'Help',
      action: () => {
        navigate('/help')
        handleCloseUserMenu()
      },
      icon: <CXIconHelp />,
      invisibile: profileOnly,
    },
    {
      label: 'Logout',
      action: () => {
        logoutUser('test', 'test')
      },
      icon: <CXIconLogout />,
      invisibile: false,
    },
  ]

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar
      sx={({ borders, palette: { white } }: Theme) => ({
        background: white.main,
        border: `${borders.borderWidth[2]} solid ${white.additional}}`,
      })}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ py: '10px' }}>
          <MDBox>
            <img src={logo} alt="Logo" width="192px" />
          </MDBox>
          <Box sx={{ display: 'flex', ml: 'auto', verticalAlign: 'center' }}>
            {adminAccess && (
              <Box mr={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <MDTypography
                  variant="textSM_M"
                  sx={({ palette: { grey } }: Theme) => ({
                    color: grey[500],
                    mr: 1,
                  })}
                >
                  Admin toggle
                </MDTypography>
                <Switch checked={adminPanel} onChange={() => toggleAdminPanel()} />
              </Box>
            )}
            {!profileOnly && (
              <>
                <MDButton variant="outlined" color="dark" startIcon={<CXIconArticle />} onClick={() => navigate('/search')} sx={{ mr: 3 }}>
                  Search
                </MDButton>
                {aiAccess && (
                  <a href="/ai-assistant/"
                     onClick={(e) => {
                       e.preventDefault(); // Prevent default link behavior
                       // Open the AI Assistant in a named window 'CompXai'
                       const compXaiWindow = window.open("/ai-assistant/", "CompXai");
                       // Check if the window was successfully opened before calling focus
                      if (compXaiWindow) {
                          compXaiWindow.focus(); // Optionally focus the window if it exists
                      } else {
                          console.log("Failed to open the window. Possibly blocked by a popup blocker.");
                      }
                     }}
                  >
                    <MDButton variant="gradient" color="primary" sx={{ mr: 2 }}>
                      <MDBox
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: ({ palette: { white } }) => white.main,
                          px: 2.5,
                          pt: '3px',
                          borderRadius: '50%',
                          height: '25px',
                          width: '25px',
                          opacity: 0.7,
                          border: '1px #1A73E8 solid',
                          mr: 2,
                        }}
                      >
                        <l-helix size="15" speed="2.5" color="#1A73E8"></l-helix>
                      </MDBox>
                      AI Assistant
                    </MDButton>
                  </a>
                )}
                <IconButton aria-label="home" onClick={() => navigate('/')} sx={{ mr: '31px', my: 'auto', cursor: 'pointer' }}>
                  <CXIconHome sx={{ width: '18px' }} />
                </IconButton>
              </>
            )}
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Jeroen" src={avatar_temp} />
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                key={'user'}
                onClick={handleCloseUserMenu}
                sx={({ borders: { borderColor, borderWidth } }: Theme) => ({
                  cursor: 'default',
                  py: 3,
                  '&:hover': {
                    backgroundColor: 'white.main',
                    borderBottom: `${borderWidth[1]} solid ${borderColor}`,
                  },
                })}
              >
                <MDBox
                  sx={{
                    height: 50,
                    width: 50,
                    backgroundColor: 'primary.additional',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    verticalAlign: 'middle',
                  }}
                >
                  <Avatar alt="Jeroen" src={avatar_temp} sx={{ m: 'auto' }} />
                </MDBox>
                <Stack ml={3}>
                  {/* <MDTypography variant="caption1">User Name</MDTypography> */}
                  <MDTypography
                    variant="caption1"
                    sx={({ typography: { fontWeightRegular } }: Theme) => ({
                      color: 'grey.500',
                      fontWeight: fontWeightRegular,
                    })}
                  >
                    {userName}
                  </MDTypography>
                </Stack>
              </MenuItem>
              {menuOptions
                .filter((menuOption) => !menuOption.invisibile)
                .map((menuOption, index) => (
                  <MenuItem key={index} onClick={() => menuOption?.action()}>
                    <>
                      <Icon sx={{ mr: 3 }}>{menuOption.icon}</Icon>
                      {menuOption.label}
                    </>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default TopBar
