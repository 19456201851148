import logo from 'img/logoLogin.png'
import FadeIn from 'global/fadein'

// @mui material components
import Container from '@mui/material/Container'
import Icon from '@mui/material/Icon'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles'

// compendiumX imports
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

const Unauthorized = () => {
  // Params
  const queryParams = new URLSearchParams(window.location.search)
  const unauthorizedMsg = queryParams.get('msg')

  return (
    <Container maxWidth={'lg'} fixed>
      <MDBox mt={9} mx={'auto'} sx={{ maxWidth: '360px' }}>
        <FadeIn visible={true}>
          <Stack sx={{ textAlign: 'center' }}>
            <MDBox>
              <img src={logo} alt="Logo" width="192px" />
            </MDBox>
            <MDTypography
              variant="displaySM"
              mt={6}
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[900],
              })}
            >
              Unauthorized access
            </MDTypography>
            <MDTypography
              variant="textMD"
              mt={2}
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              {unauthorizedMsg}
            </MDTypography>
            <MDTypography
              variant="textSM"
              mt={2}
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Please contact <Link href={`mailto:support@compendium-x.com`}>support@compendium-x.com</Link> for more information about the Single Sign On subscription.
            </MDTypography>
            <Link href="/login">
              <MDBox
                mt={8}
                sx={{
                  cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Icon sx={{ mr: 3 }}>arrow_back</Icon>
                <MDTypography variant="textSM">Back to log in</MDTypography>
              </MDBox>
            </Link>
          </Stack>
        </FadeIn>
      </MDBox>
    </Container>
  )
}

export default Unauthorized
