// @ts-ignore
import Cookies from 'js-cookie'

export const getChangePassword = async (email: string, currentPassword: string, password: string) => {
  const credentials = {
    email: email,
    password: currentPassword,
    new_password: password,
    new_password_confirm: password,
  }
  const cookies = Cookies.get()
  try {
    const response = await fetch(`${process.env.REACT_APP_API_LOGIN_URL}/change`, {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({ 'Content-Type': 'application/json', 'X-CSRF-Token': cookies['XSRF-TOKEN'] }),
      body: JSON.stringify(credentials),
    })
    const result = await response.json()
    if (response.ok) {
      return result
    }
    if (!response.ok) await Promise.reject(result)
    return response
  } catch (error) {
    return error
  }
}
