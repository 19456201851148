export const getSearchTabs = () => {}

export const deleteSearchTab = () => {}

export const boldString = (str: string, substr: string) => {
  // To build a combined/ bold string for search results remove special characters
  const cleanedStr = str.replace(/[^a-zA-Z0-9 ]/g, '')
  const cleanedSubstr = substr.replace(/[^a-zA-Z0-9 ]/g, '')
  // Return with partially bold text
  return cleanedStr.replace(RegExp(cleanedSubstr, 'g'), `<b>${cleanedSubstr}</b>`)
}
