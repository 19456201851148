// Types
import { HelpItemProps } from './Help.type'

const data = [
  {
    question: 'How to create an account?',
    answer: [
      'A Compendium X  Account gives you access to the Regulatory Reporting Platform. With a Compendium X Account, you can do things like:',
      '•  Search through the ITS, Single Rulebook, Q&A and DPM',
      '•  Explore the regulatory context of a template or specific cell in a template',
      '•  Browse through related articles and regulations referenced in the article content.',
      'Steps',
      '•  Go to login page',
      "•  Click on <a href='/signup'>Sign up</a>",
      '•  Enter your name, email address and telephone number',
      '•  If your organization is already an active user of the Compendium X platform. After verification of the information by RegBird, an email account will be created and a welcomes mail will be sent to your email address.',
    ],
  },
  {
    question: 'How to change or reset your password?',
    answer: [
      '•  Open your Compendium X  Account. You might need to sign in.',
      "•  Under 'profile', select Change Password",
      '•  Enter your new password, then select Change Password.',
    ],
  },
  {
    question: "Can't sign in to your Compendium X Account?",
    answer: [
      "You forgot your password. Go to the login screen and select <a href='/reset'>Forgot password</a>",
      'You forgot your username you use to sign in. Your email address is your username',
      "You know your username and password, but you can't sign in.",
      'You get an error message.',
      '<u>You are already logged on.</u>',
      'Steps:',
      '• Close all browser windows and retry to sign in',
      'Make sure that:',
      '•  You have no active logins to Compendium X via other browsers. Please don’t forget to check other devices on which you might have signed in to Compendium X',
      '<u>Username or password is wrong, invalid, or unrecognized.</u>',
      'Make sure that:',
      "•  There isn't a typo in your username or password.",
      "•  You've entered your full email address. For example, 'username@bankx.com' instead of 'username@bankx.'",
      '•  Caps lock is turned off.',
      '•  Your keyboard is in the right language.',
      '•  Refresh your browser and try signing in again.',
      "•  If you're still getting the error message, follow the steps to recover your account <a href='/reset'>Forgot password</a>",
      "•  If we send you an email but you don't get it",
      '•  Check your spam or bulk mail folder.',
      "<u>If the email isn't there:</u>",
      '•  Add noreply@compendium-x.com and support@compendium-x.com to your address book.',
      '•  Request another email by following the steps to recover your account.',
      '<u>Account is inactive, suspended or disabled</u>',
      'What happens when your account is disabled:',
      "•  You can't sign in to your Compendium X  Account.",
      '•  Your information will be retained for one year. After one year all your personal data will be deleted.',
      'Find out why:',
      '•  Sign in to your Compendium X  Account on a browser.',
      "•  If your account is disabled, you'll get an explanation.",
      'Why accounts are disabled:',
      '•  Max number of users is reached',
      '•  The contractual maximum of number of users is reached.',
      'Please contact your system owner to request additional users via the compendium service desk.',
      '•  High volumes',
      'Compendium X  Account does not allow for automated information retrieval or exceptional large network traffic.',
      'Please contact service desk if:',
      '•  Impersonation & misrepresentation of identity is suspected.',
      '•  Account hacking or hijacking is suspected.',
    ],
  },
  {
    question: 'You logged on but no content is shown.',
    answer: [
      '•  Most likely you are already logged on.',
      'Close all browser windows and retry to sign in.',
      'Make sure that:',
      '•  You have no active logins to Compendium X via other browsers. Please  or on other devices.',
    ],
  },
  {
    question: 'You don’t receive the sign up email for Compendium X',
    answer: [
      '•  Retry to perform the Sign up process. Ensure your email address is correct and the additional requested information is valid.',
      "•  If we send you an email but you don't get it",
      '•  Check your spam or bulk mail folder.',
      "If the email isn't there:",
      '•  Add noreply@compendium-x.com and support@compendium-x.com to your address book.',
      'Request another email by following the steps to recover your account.',
    ],
  },
]

export async function getHelpData(): Promise<HelpItemProps[]> {
  //   const token = localStorage.getItem("authentication-Token");
  try {
    // const response = await fetch(
    //   `${process.env.REACT_APP_API_URL}/api/entry/v1/library/${collection}/document`,
    //   {
    //     method: "GET",
    //     headers: {
    //       "authentication-Token": token || "",
    //     },
    //   }
    // );
    const result = data
    return result
  } catch (error) {
    return []
  }
}
