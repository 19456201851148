// Functional
import { getSearchQuery } from 'global/getSearchQuery'

// Types
import { SearchFilterProps } from './SearchFilters.type'

export const getSearchFilters = async (
  searchTerm?: string,
  searchFilters?: {
    relatedTaxonomyCodes?: string
    relatedRows?: string
    relatedColumns?: string
    relatedTemplateCodes?: string
  },
  filterDocuments?: any[],
  filterDocumentCollection?: any[],
  filterDate?: any
): Promise<SearchFilterProps> => {
  const searchResultsQuery = getSearchQuery(searchFilters, filterDocuments, filterDocumentCollection, filterDate) || ''

  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/search/facet?search=${searchTerm}${searchResultsQuery}`, {
    credentials: 'include',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }
  const result = await response.json()
  if (response.ok) {
    return {
      relatedColumns: result['related_positions/Column']?.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())),
      relatedRows: result['related_positions/Row']?.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())),
      relatedTaxonomyCodes: result['related_positions/TaxonomyCode']?.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())),
      relatedTemplateCodes: result['related_positions/TemplateCode']?.sort((a: any, b: any) => a.value.toLowerCase().localeCompare(b.value.toLowerCase())),
      documentCollections: result['document_collection'],
      documentLongnames: result['document_longname'],
      dateFilter: result['document_date'],
    }
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}
