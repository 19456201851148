import MDTypography from 'components/MDTypography'

var jsdiff = require('diff');

interface Props {
  type: 'chars' | 'words' | 'wordsWithSpace' | 'sentences' | 'json';
  inputA: string;
  inputB: string;
}

const DiffNext: React.FC<Props> = (props) => {
  const { type, inputA, inputB } = props;

  const fnMap: { [key in Props['type']]: any } = {
    chars: jsdiff.diffChars,
    words: jsdiff.diffWords,
    wordsWithSpace: jsdiff.diffWordsWithSpace,
    sentences: jsdiff.diffSentences,
    json: jsdiff.diffJson,
  };

  const diffFunction = fnMap[type];

  const diff = diffFunction(inputA, inputB);

  const result = diff.map((part: any, index: number) => {
    const spanStyle = {
      color: part.added ? '#039855' : part.removed ? '#EF5350' : '#7b809a',
      backgroundColor: part.added ? '#dff0d8' : part.removed ? '#f2dede' : 'transparent',
      textDecoration: part.removed ? 'line-through' : 'none',
    };

    return (
      <MDTypography key={index} style={spanStyle} variant="textMD">
        {part.value}
      </MDTypography>
    );
  });

  return <>{result}</>;
};

export default DiffNext;
