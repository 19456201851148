import { forwardRef, FC } from "react";

// @mui material components
import { TabProps } from "@mui/material";

// Custom styles for CXTab
import CXTabRoot from "./CXTabRoot";

// declaring props types for CXTab
interface Props extends TabProps {
  variant?: "contained" | "gradient";
  bgColor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  coloredShadow?: string;
  [key: string]: any;
  active?: boolean;
}

const CXTab: FC<Props> = forwardRef(
  (
    {
      variant,
      bgColor,
      color,
      opacity,
      borderRadius,
      shadow,
      coloredShadow,
      active,
      ...rest
    },
    ref
  ) => (
    <>
      <CXTabRoot
        {...rest}
        ref={ref}
        ownerState={{
          variant,
          bgColor,
          color,
          opacity,
          borderRadius,
          shadow,
          coloredShadow,
          active,
        }}
      />
    </>
  )
);

// Declaring default props for CXTab
CXTab.defaultProps = {
  variant: "contained",
  bgColor: "transparent",
  color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
  coloredShadow: "none",
  active: false,
};

export default CXTab;
