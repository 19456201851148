import { useContext } from "react";
import { orderBy } from "lodash";
import moment from "moment";

// mui imports
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";

// compendiumX imports
import CXTab from "components/CXTab";
import MDBox from "components/MDBox";

// Context
import { SearchContext } from "context/SearchContext";

const SearchTabs = () => {
  // Context
  const {
    activeSearchTab,
    searchTabs,
    getAddTab,
    getRemoveTab,
    getSetActiveTab,
  } = useContext(SearchContext);
  const orderedSearchTabs = orderBy(searchTabs, ["active", "id"], ["desc"]);

  return (
    <MDBox sx={{ display: "flex", alignItems: "center" }} height="50px">
      <IconButton
        color="primary"
        onClick={() => getAddTab()}
        disabled={!activeSearchTab}
      >
        <Icon>add</Icon>
      </IconButton>
      <Tabs>
        {orderedSearchTabs?.map((tab, index) => (
          <>
            <CXTab
              key={index}
              iconPosition="end"
              label={
                tab.searchTerm ||
                moment.unix(tab.id).format("DD-MM HH:mm") ||
                "New search"
              }
              value={tab.searchTerm}
              onClick={() => getSetActiveTab(tab.id)}
              active={tab.active}
              sx={{ color: "black !important" }}
              icon={
                <>
                  <>
                    <Chip
                      label={tab.count || 0}
                      sx={{ height: "24px", py: "2px", mx: "8px" }}
                    />
                    <Icon
                      onClick={(e) => {
                        e.stopPropagation();
                        getRemoveTab(tab.id);
                      }}
                      sx={{ color: "primary.focus" }}
                    >
                      clear
                    </Icon>
                  </>
                </>
              }
            />
            {!tab.active && (
              <Divider
                orientation="vertical"
                sx={{ height: "30px", m: "10px 0 0 0" }}
              />
            )}
          </>
        ))}
      </Tabs>
    </MDBox>
  );
};
export default SearchTabs;
