import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FadeIn from 'global/fadein'

// @mui material components
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Stack from '@mui/system/Stack'
import { Theme } from '@mui/material/styles/createTheme'

// compendiumX imports
import { CXIconCategory, CXIconFile } from 'components/CXIcon/CXIcon'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import ModuleWrapper from 'wrappers/ModuleWrapper'

// Functional
import { getColor } from 'global/helpers'
import { getQuickAccessData } from './QuickAccess.service'

// Types
import QuickAccessItemProps from './QuickAccess.type'

// Context
import { ErrorContext } from 'context/ErrorContext'

const QuickAccess = () => {
  const { setErrorMessage } = useContext(ErrorContext)
  //Module specific
  const [loading, setLoading] = useState(false)
  //Data
  const [quickAccessData, setQuickAccessData] = useState<QuickAccessItemProps[]>([])

  useEffect(() => {
    const fetchQuickAccessData = async (): Promise<void> => {
      setLoading(true)
      try {
        setQuickAccessData(await getQuickAccessData())
      } catch (error: any) {
        setErrorMessage(error.status)
      }
      setLoading(false)
    }
    fetchQuickAccessData()
    // eslint-disable-next-line
  }, [])

  const quickAccessItemTemplate = (quickAccessItem: QuickAccessItemProps, index: number) => {
    return (
      <Grid item xs={12} md={6} lg={4} key={index}>
        <Link to={`/collections/${quickAccessItem.collection}`}>
          <MDBox
            sx={({ borders, boxShadows }: Theme) => ({
              borderRadius: borders.borderRadius,
              cursor: 'pointer',
              transition: 'box-shadow 0.3s ease-out',
              '&:hover': {
                boxShadow: boxShadows.sm,
              },
            })}
          >
            <MDBox
              p={8}
              sx={({ borders }: Theme) => ({
                borderRadius: borders.borderRadius,
                border: `${borders.borderWidth[1]} solid ${borders.borderColor}}`,
              })}
            >
              <CXIconCategory fontSize="large" sx={{ color: getColor(index), width: '40px', height: '36px' }} />
              <MDTypography
                mt={6}
                mb={1}
                variant="textLG_M"
                sx={({ palette: { grey } }: Theme) => ({
                  color: grey[800],
                  display: 'block',
                })}
              >
                {quickAccessItem.collection}
              </MDTypography>
              <MDTypography variant="textSM" color="secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                <CXIconFile sx={{ mr: 1 }} />
                {quickAccessItem.file_count} Documents
              </MDTypography>
            </MDBox>
          </MDBox>
        </Link>
      </Grid>
    )
  }

  return (
    <ModuleWrapper moduleName="Quick Access">
      {loading && (
        <FadeIn visible={loading}>
          <Stack spacing={4} sx={{ alignItems: 'center' }}>
            <CircularProgress size={100} />
            <MDTypography
              variant="h6"
              sx={({ palette: { grey } }: Theme) => ({
                color: grey[500],
              })}
            >
              Loading user...
            </MDTypography>
          </Stack>
        </FadeIn>
      )}
      {!loading && (
        <FadeIn>
          <Grid container spacing={4}>
            {quickAccessData?.map((quickAccessItem, index) => quickAccessItemTemplate(quickAccessItem, index))}
          </Grid>
        </FadeIn>
      )}
    </ModuleWrapper>
  )
}

export default QuickAccess
