// @mui imports
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Theme } from "@mui/material/styles/createTheme";

// compendiumX imports
import MDBox from "components/MDBox";

// Modules
import SearchContainer from "modules/SearchContainer/SearchContainer";
import SearchFilters from "modules/SearchFilters/SearchFilters";
import SearchResults from "modules/SearchResults/SearchResults";
import SearchTabs from "modules/SearchTabs/SearchTabs";

// Functional

const Search = () => {
  return (
    <Container maxWidth="xl" sx={{ mt: "80px" }}>
      <Stack direction="row">
        <SearchFilters />
        <MDBox sx={{ width: "100%" }}>
          <SearchTabs />
          <MDBox
            py={5}
            px={9}
            sx={({ borders, palette: { grey } }: Theme) => ({
              backgroundColor: grey[200],
              border: `${borders.borderColor} 1px solid`,
              borderRadius: borders.borderRadius,
            })}
          >
            <SearchContainer />
            <SearchResults />
          </MDBox>
        </MDBox>
      </Stack>
    </Container>
  );
};

export default Search;
