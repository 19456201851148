// @mui material components
import { styled, Theme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

export default styled(Tab)(
  ({ theme, ownerState }: { theme?: Theme | any; ownerState: any }) => {
    const { active } = ownerState;

    // styles for CX tab not active
    const inactiveStyles = () => {
      return {
        backgroundColor: "white",
        border: "none",
      };
    };
    return {
      ...(!active && inactiveStyles()),
    };
  }
);
