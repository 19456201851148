// Material Dashboard 2 PRO React TS Base Styles
import colors from 'assets/theme/base/colors'

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from 'assets/theme/functions/pxToRem'

const { dark } = colors

// types
interface Types {
  fontFamily: string
  fontWeightLighter: number
  fontWeightLight: number
  fontWeightRegular: number
  fontWeightMedium: number
  fontWeightSemiBold: number
  fontWeightBold: number
  displayXS: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  displaySM: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  displaySM_M: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textXXS: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textXS: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textXS_M: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textXS_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textSM: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textSM_M: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textSM_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textMD: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textMD_M: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textMD_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textLG: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textLG_M: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textXL: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textXL_M: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  textXL_SB: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: string
  }
  h1: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: string
  }
  h2: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  h3: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: string
  }
  h4: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  h5: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  h6: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  subtitle1: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  subtitle2: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  body1: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  body2: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  button: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
    textTransform: any
  }
  caption1: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  caption2: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    lineHeight: number
  }
  overline: {
    fontFamily: string
  }
  size: {
    xxs: string
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    '2xl': string
    '3xl': string
  }

  lineHeight: {
    sm: number
    md: number
    lg: number
  }
}

const baseProperties = {
  fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
  fontWeightLighter: 100,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontSizeXXS: pxToRem(10.4),
  fontSizeXS: pxToRem(12),
  fontSizeSM: pxToRem(14),
  fontSizeMD: pxToRem(16),
  fontSizeLG: pxToRem(18),
  fontSizeXL: pxToRem(20),
  fontSize2XL: pxToRem(24),
  fontSize3XL: pxToRem(30),
}

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  color: dark.main,
  fontWeight: baseProperties.fontWeightSemiBold,
}

const typography: Types = {
  fontFamily: baseProperties.fontFamily,
  fontWeightLighter: baseProperties.fontWeightLighter,
  fontWeightLight: baseProperties.fontWeightLight,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightMedium: baseProperties.fontWeightMedium,
  fontWeightSemiBold: baseProperties.fontWeightSemiBold,
  fontWeightBold: baseProperties.fontWeightBold,

  displayXS: {
    fontWeight: baseProperties.fontWeightMedium,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize2XL,
    lineHeight: pxToRem(32),
  },

  displaySM: {
    fontWeight: baseProperties.fontWeightSemiBold,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize3XL,
    lineHeight: pxToRem(38),
  },

  displaySM_M: {
    fontWeight: baseProperties.fontWeightMedium,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSize3XL,
    lineHeight: pxToRem(38),
  },

  textXXS: {
    fontWeight: baseProperties.fontWeightRegular,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    lineHeight: pxToRem(16),
  },

  textXS: {
    fontWeight: baseProperties.fontWeightRegular,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    lineHeight: pxToRem(18),
  },

  textXS_SB: {
    fontWeight: baseProperties.fontWeightSemiBold,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    lineHeight: pxToRem(18),
  },

  textXS_M: {
    fontWeight: baseProperties.fontWeightMedium,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    lineHeight: pxToRem(18),
  },

  textSM: {
    fontWeight: baseProperties.fontWeightRegular,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    lineHeight: pxToRem(20),
  },

  textSM_SB: {
    fontWeight: baseProperties.fontWeightSemiBold,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    lineHeight: pxToRem(20),
  },

  textSM_M: {
    fontWeight: baseProperties.fontWeightMedium,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    lineHeight: pxToRem(20),
  },

  textMD: {
    fontWeight: baseProperties.fontWeightRegular,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    lineHeight: pxToRem(24),
  },

  textMD_M: {
    fontWeight: baseProperties.fontWeightMedium,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    lineHeight: pxToRem(24),
  },

  textMD_SB: {
    fontWeight: baseProperties.fontWeightSemiBold,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    lineHeight: pxToRem(24),
  },

  textLG: {
    fontWeight: baseProperties.fontWeightMedium,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    lineHeight: pxToRem(28),
  },

  textLG_M: {
    fontWeight: baseProperties.fontWeightMedium,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    lineHeight: pxToRem(28),
  },

  textXL: {
    fontWeight: baseProperties.fontWeightRegular,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    lineHeight: pxToRem(30),
  },

  textXL_M: {
    fontWeight: baseProperties.fontWeightMedium,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    lineHeight: pxToRem(30),
  },

  textXL_SB: {
    fontWeight: baseProperties.fontWeightSemiBold,
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    lineHeight: pxToRem(30),
  },

  h1: {
    fontSize: pxToRem(30),
    lineHeight: pxToRem(38),
    ...baseHeadingProperties,
  },

  h2: {
    fontSize: pxToRem(36),
    lineHeight: 1.3,
    ...baseHeadingProperties,
  },
  h3: {
    fontFamily: baseProperties.fontFamily,
    color: dark.main,
    fontSize: pxToRem(30),
    fontWeight: baseProperties.fontWeightSemiBold,
    lineHeight: pxToRem(38),
  },

  h4: {
    fontSize: pxToRem(24),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h5: {
    fontSize: pxToRem(20),
    lineHeight: 1.375,
    ...baseHeadingProperties,
  },

  h6: {
    fontFamily: baseProperties.fontFamily,
    color: dark.main,
    fontSize: pxToRem(24),
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.625,
  },

  subtitle1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.625,
  },

  subtitle2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.6,
  },

  body1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXL,
    fontWeight: baseProperties.fontWeightRegular,
    lineHeight: 1.625,
  },

  body2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.6,
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.5,
    textTransform: 'none',
  },

  caption1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    fontWeight: baseProperties.fontWeightMedium,
    lineHeight: 1.5,
  },

  caption2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeXS,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.25,
  },

  overline: {
    fontFamily: baseProperties.fontFamily,
  },

  size: {
    xxs: baseProperties.fontSizeXXS,
    xs: baseProperties.fontSizeXS,
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
    xl: baseProperties.fontSizeXL,
    '2xl': baseProperties.fontSize2XL,
    '3xl': baseProperties.fontSize3XL,
  },

  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2,
  },
}

export default typography
