// @mui imports
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

// compendiumX imports
import MDBox from "components/MDBox";

// Modules
import RecentSearch from "modules/RecentSearch/RecentSearch";
import QuickAccess from "modules/QuickAccess/QuickAccess";

const Dashboard = () => {
  return (
    <Container maxWidth={"xl"} fixed sx={{ mt: "125px" }}>
      <Stack spacing={9}>
        <MDBox>
          <QuickAccess />
        </MDBox>
        <MDBox>
          <RecentSearch />
        </MDBox>
      </Stack>
    </Container>
  );
};

export default Dashboard;
