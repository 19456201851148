// @mui material components
import Fade from "@mui/material/Fade";

// Material Dashboard 2 PRO React TS Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

// Material Dashboard 2 PRO React TS Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { black, grey } = colors;
const { size, fontWeightRegular } = typography;
const { borderRadius } = borders;

// types
type Types = any;

const tooltip: Types = {
  defaultProps: {
    TransitionComponent: Fade,
  },

  styleOverrides: {
    tooltip: {
      maxWidth: pxToRem(440),
      backgroundColor: grey[50],
      color: grey[500],
      fontSize: size.sm,
      fontWeight: fontWeightRegular,
      border: "1px solid #EAECF0",
      borderRadius: borderRadius.md,
      opacity: 0.7,
      padding: `${pxToRem(20)} ${pxToRem(28)}}`,
    },

    arrow: {
      color: black.main,
    },
  },
};

export default tooltip;
