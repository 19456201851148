import _ from 'lodash'
import moment from 'moment'

export const getSearchQuery = (
  searchFilters?: {
    relatedTaxonomyCodes?: string
    relatedRows?: string
    relatedColumns?: string
    relatedTemplateCodes?: string
  },
  filterDocuments?: any[],
  filterDocumentCollection?: any[],
  filterDate?: any
) => {
  let searchPresent
  let relatedTaxonomyCodes
  let relatedRows
  let relatedColumns
  let relatedTemplateCodes
  let searchFilterDocuments
  let searchFilterDocumentCollection
  let searchFilterDocumentDate = filterDate ? filterDate : moment().format('YYYY-MM-DDT00:00:00') + 'Z'

  const cleanedSearchFilters = _.omitBy(searchFilters, _.isNil)

  if (
    (searchFilters && Object.keys(cleanedSearchFilters).length !== 0) ||
    (filterDocuments && filterDocuments?.length > 0) ||
    (filterDocumentCollection && filterDocumentCollection.length > 0) ||
    searchFilterDocumentDate
  ) {
    searchPresent = ' &filter='
  }
  if (searchFilters?.relatedTaxonomyCodes) {
    relatedTaxonomyCodes = `related_positions/any(x: x/TaxonomyCode eq '${searchFilters?.relatedTaxonomyCodes}')`
  }
  if (searchFilters?.relatedTemplateCodes) {
    relatedTemplateCodes = `related_positions/any(x: x/TemplateCode eq '${searchFilters?.relatedTemplateCodes}')`
  }
  if (searchFilters?.relatedRows) {
    relatedRows = `related_positions/any(x: x/Row eq '${searchFilters?.relatedRows}')`
  }
  if (searchFilters?.relatedColumns) {
    relatedColumns = `related_positions/any(x: x/Column eq '${searchFilters?.relatedColumns}')`
  }
  if (filterDocuments && filterDocuments.length > 0) {
    searchFilterDocuments = `search.in(document_longname, '${filterDocuments.join('|')}','|')`
  }
  if (filterDocumentCollection && filterDocumentCollection.length > 0) {
    searchFilterDocumentCollection = `search.in(document_collection, '${filterDocumentCollection.join('|')}','|')`
  }
  if (searchFilterDocumentDate) {
    searchFilterDocumentDate = `(document_date lt ${searchFilterDocumentDate} and document_date_next ge ${searchFilterDocumentDate})`
  }

  const relatedPositions = [
    relatedTaxonomyCodes,
    relatedTemplateCodes,
    relatedColumns,
    relatedRows,
    searchFilterDocuments,
    searchFilterDocumentCollection,
    searchFilterDocumentDate,
  ]

  const relatedPositionsDefined = relatedPositions.filter((element) => {
    return element !== undefined
  })

  const splitArray = relatedPositionsDefined.join(' and ')
  if (searchPresent) {
    return String(searchPresent + splitArray)
  }
}
