import { createContext, useEffect, useState } from 'react'
import moment from 'moment'

enum SearchSorter {
  'document_date' = 'document_date',
  'document_name' = 'document_name',
  'search.score()' = 'search.score()',
}

export interface StandardSearchContextProps {
  searchTerm?: string
  searchFilters?: {
    relatedColumns?: any
    relatedRows?: any
    relatedTaxonomyCodes?: any
    relatedTemplateCodes?: any
  }
  searchSorter: SearchSorter
  searchActivePage: number
  filterDocumentCollection: any[]
  filterDocuments: any[]
  filterDate: any
}

export interface SearchTabProps extends StandardSearchContextProps {
  id: number
  active: boolean
  count?: number
}

export interface SearchContextProps extends StandardSearchContextProps {
  activeSearchTab: SearchTabProps
  searchTabs?: SearchTabProps[]
  getSetSearchTerm: any
  getSetSearchFilters: any
  getSetSearchSorter: any
  getSetSearchActivePage: any
  getSetFilterDocumentCollection: any
  getSetFilterDocuments: any
  getSetFilterDate: any
  getAddTab: any
  setQATab: any
  setRecentTab: any
  getRemoveTab: any
  getSetActiveTab: any
  getSetTabCount: any
}

export const SearchContext = createContext({} as SearchContextProps)

export const SearchProvider = ({ children }: any) => {
  // TABS
  const storageSearchTabs = localStorage.getItem('searchTabs')
  const emptyTab = {
    active: true,
    searchTerm: '',
    id: moment().unix(),
    searchActivePage: 1,
    searchSorter: SearchSorter['search.score()'],
    filterDocumentCollection: [],
    filterDocuments: [],
  }
  const activeStorageSearchTab = storageSearchTabs ? JSON.parse(storageSearchTabs).find((storageSearchTab: SearchTabProps) => storageSearchTab.active) : emptyTab
  const [activeSearchTab, setActiveSearchTab] = useState<SearchTabProps>(activeStorageSearchTab)
  const [searchTabs, setSearchTabs] = useState<SearchTabProps[]>([])

  //Rest
  const [searchTerm, setSearchTerm] = useState(activeSearchTab.searchTerm)
  // const prevSearchTerm = usePrevious(searchTerm);
  const [searchFilters, setSearchFilters] = useState(activeSearchTab.searchFilters)
  const [searchSorter, setSearchSorter] = useState<SearchSorter>(activeSearchTab.searchSorter)
  const [searchActivePage, setSearchActivePage] = useState(activeSearchTab.searchActivePage)
  const [filterDocumentCollection, setFilterDocumentCollection] = useState(activeSearchTab.filterDocumentCollection)
  const [filterDocuments, setFilterDocuments] = useState(activeSearchTab.filterDocuments)
  const [filterDate, setFilterDate] = useState(activeSearchTab.filterDate)

  const loadSearchContext = async () => {
    setSearchTerm(activeSearchTab.searchTerm)
    setSearchFilters(activeSearchTab.searchFilters)
    setSearchSorter(activeSearchTab.searchSorter)
    setSearchActivePage(Number(activeSearchTab.searchActivePage))
    setFilterDocumentCollection(activeSearchTab.filterDocumentCollection)
    setFilterDocuments(activeSearchTab.filterDocuments)
    setFilterDate(activeSearchTab.filterDate)
  }

  const getAddTab = async () => {
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) => {
        return { ...searchTab, active: false }
      })
      setSearchTabs(mappedSearchTabs.concat(emptyTab))
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs.concat(emptyTab)))
      window.location.reload()
    }
  }

  const setQATab = async (documents?: any) => {
    const qaTab = {
      active: true,
      id: moment().unix(),
      searchActivePage: 1,
      searchSorter: SearchSorter['search.score()'],
      filterDocumentCollection: [],
      filterDocuments: [documents],
      filterDate: moment().format('YYYY-MM-DDT00:00:00') + 'Z',
    }
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) => {
        return { ...searchTab, active: false }
      })
      setSearchTabs(mappedSearchTabs.concat(qaTab))
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs.concat(qaTab)))
    } else {
      const emptyTab = {
        active: true,
        id: moment().unix(),
        searchActivePage: 1,
        searchSorter: SearchSorter['search.score()'],
        filterDocumentCollection: [],
        filterDocuments: [documents],
        filterDate: moment().format('YYYY-MM-DDT00:00:00') + 'Z',
      }
      setSearchTabs([emptyTab])
      localStorage.setItem('searchTabs', JSON.stringify([emptyTab]))
    }
  }

  const setRecentTab = async (searchTerm: string) => {
    const recentSearchTab = {
      active: true,
      searchTerm: searchTerm,
      id: moment().unix(),
      searchActivePage: 1,
      searchSorter: SearchSorter['search.score()'],
      filterDocumentCollection: [],
      filterDocuments: [],
      filterDate: moment().format('YYYY-MM-DDT00:00:00') + 'Z',
    }
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) => {
        return { ...searchTab, active: false }
      })
      setSearchTabs(mappedSearchTabs.concat(recentSearchTab))
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs.concat(recentSearchTab)))
    } else {
      const emptyTab = {
        active: true,
        searchTerm: searchTerm,
        id: moment().unix(),
        searchActivePage: 1,
        searchSorter: SearchSorter['search.score()'],
        filterDocumentCollection: [],
        filterDocuments: [],
        filterDate: moment().format('YYYY-MM-DDT00:00:00') + 'Z',
      }
      setSearchTabs([emptyTab])
      localStorage.setItem('searchTabs', JSON.stringify([emptyTab]))
    }
  }

  const getRemoveTab = async (id: number) => {
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.filter((searchTab: SearchTabProps) => searchTab.id !== id)
      let mappedSearchTabsWithActive
      if (mappedSearchTabs.length === 0) {
        mappedSearchTabsWithActive = [emptyTab]
        window.location.reload()
      } else {
        const activeItem = { ...mappedSearchTabs.pop(), active: true }
        const rest = mappedSearchTabs.filter((mappedSearchTab: SearchTabProps) => mappedSearchTab.id !== activeItem.id)
        mappedSearchTabsWithActive = rest.concat(activeItem)
      }
      setSearchTabs(mappedSearchTabsWithActive)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabsWithActive))
    }
  }

  const getSetActiveTab = async (id: number) => {
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) => {
        return searchTab.id === id ? { ...searchTab, active: true } : { ...searchTab, active: false }
      })
      setSearchTabs(mappedSearchTabs)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs))
      window.location.reload()
    }
  }

  const getSetTabCount = async (count: number) => {
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) => {
        return searchTab.active ? { ...searchTab, count: count } : { ...searchTab }
      })
      setSearchTabs(mappedSearchTabs)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs))
    }
  }

  const getSetSearchTerm = async (searchTerm: string) => {
    setSearchTerm(searchTerm)
    localStorage.setItem('searchTerm', searchTerm)

    // TABS
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) => (searchTab.active ? { ...searchTab, searchTerm: searchTerm } : searchTab))
      setSearchTabs(mappedSearchTabs)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs))
    } else {
      const emptyTab = {
        active: true,
        searchTerm: searchTerm,
        id: moment().unix(),
        searchActivePage: 1,
        searchSorter: SearchSorter['search.score()'],
        filterDocumentCollection: [],
        filterDocuments: [],
        filterDate: moment().format('YYYY-MM-DDT00:00:00') + 'Z',
      }
      setSearchTabs([emptyTab])
      localStorage.setItem('searchTabs', JSON.stringify([emptyTab]))
    }
  }

  useEffect(() => {
    const activeTab = searchTabs.find((tab) => tab.active)
    if (activeTab) {
      setActiveSearchTab(activeTab)
    }
  }, [searchTabs])

  const getSetSearchFilters = async (filterType: 'relatedColumns' | 'relatedRows' | 'relatedTaxonomyCodes' | 'relatedTemplateCodes', filterValue: string | number) => {
    const newSearchFilters = { ...searchFilters, [filterType]: filterValue }
    setSearchFilters(newSearchFilters)
    localStorage.setItem('searchFilters', JSON.stringify(newSearchFilters))

    // TABS
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) => (searchTab.active ? { ...searchTab, searchFilters: newSearchFilters } : searchTab))
      setSearchTabs(mappedSearchTabs)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs))
    } else {
      const emptyTab = {
        active: true,
        searchTerm: searchTerm,
        id: moment().unix(),
        searchActivePage: 1,
        searchSorter: SearchSorter['search.score()'],
        searchFilters: newSearchFilters,
        filterDocumentCollection: [],
        filterDocuments: [],
        filterDate: moment().format('YYYY-MM-DDT00:00:00') + 'Z',
      }
      setSearchTabs([emptyTab])
      localStorage.setItem('searchTabs', JSON.stringify([emptyTab]))
    }
  }

  const getSetSearchSorter = async (searchSorter: SearchSorter) => {
    setSearchSorter(searchSorter)
    localStorage.setItem('searchSorter', searchSorter)

    // TABS
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) => (searchTab.active ? { ...searchTab, searchSorter: searchSorter } : searchTab))
      setSearchTabs(mappedSearchTabs)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs))
    }
  }

  const getSetSearchActivePage = async (page: number) => {
    setSearchActivePage(page)
    localStorage.setItem('searchActivePage', String(page))

    // TABS
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) => (searchTab.active ? { ...searchTab, searchActivePage: String(page) } : searchTab))
      setSearchTabs(mappedSearchTabs)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs))
    }
  }

  const getSetFilterDocumentCollection = async (documentCollection: string[]) => {
    setFilterDocumentCollection(documentCollection)
    localStorage.setItem('filterDocumentCollection', JSON.stringify(documentCollection))

    // TABS
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)

      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) =>
        searchTab.active
          ? {
              ...searchTab,
              filterDocumentCollection: documentCollection,
            }
          : searchTab
      )
      setSearchTabs(mappedSearchTabs)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs))
    } else {
      const emptyTab = {
        active: true,
        searchTerm: searchTerm,
        id: moment().unix(),
        searchActivePage: 1,
        searchSorter: SearchSorter['search.score()'],
        filterDocumentCollection: documentCollection,
        filterDocuments: [],
        filterDate: moment().format('YYYY-MM-DDT00:00:00') + 'Z',
      }
      setSearchTabs([emptyTab])
      localStorage.setItem('searchTabs', JSON.stringify([emptyTab]))
    }
  }

  const getSetFilterDocuments = async (documents: []) => {
    setFilterDocuments(documents)
    localStorage.setItem('filterDocuments', JSON.stringify(documents))

    // TABS
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) =>
        searchTab.active
          ? {
              ...searchTab,
              filterDocuments: documents,
            }
          : searchTab
      )
      setSearchTabs(mappedSearchTabs)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs))
    } else {
      const emptyTab = {
        active: true,
        searchTerm: searchTerm,
        id: moment().unix(),
        searchActivePage: 1,
        searchSorter: SearchSorter['search.score()'],
        filterDocumentCollection: [],
        filterDocuments: documents,
        filterDate: moment().format('YYYY-MM-DDT00:00:00') + 'Z',
      }
      setSearchTabs([emptyTab])
      localStorage.setItem('searchTabs', JSON.stringify([emptyTab]))
    }
  }

  const getSetFilterDate = async (date: any) => {
    setFilterDate(date)
    localStorage.setItem('filterDate', date)

    // TABS
    if (storageSearchTabs) {
      const parsedSearchTabs = JSON.parse(storageSearchTabs)
      const mappedSearchTabs = parsedSearchTabs.map((searchTab: SearchTabProps) =>
        searchTab.active
          ? {
              ...searchTab,
              filterDate: date,
            }
          : searchTab
      )
      setSearchTabs(mappedSearchTabs)
      localStorage.setItem('searchTabs', JSON.stringify(mappedSearchTabs))
    } else {
      const emptyTab = {
        active: true,
        searchTerm: searchTerm,
        id: moment().unix(),
        searchActivePage: 1,
        searchSorter: SearchSorter['search.score()'],
        filterDocumentCollection: [],
        filterDocuments: [],
        filterDate: date,
      }
      setSearchTabs([emptyTab])
      localStorage.setItem('searchTabs', JSON.stringify([emptyTab]))
    }
  }

  useEffect(() => {
    loadSearchContext()
    // eslint-disable-next-line
  }, [])

  // // Reset filters
  // useEffect(() => {
  //   if (prevSearchTerm !== searchTerm) {
  //     const newSearchFilters = {};
  //     setSearchFilters(newSearchFilters);
  //     localStorage.removeItem("searchFilters");
  //     setSearchActivePage(1);
  //     localStorage.removeItem("searchActivePage");
  //   }
  //   // eslint-disable-next-line
  // }, [searchTerm]);

  // function usePrevious(value: any) {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   }, [value]);
  //   return ref.current;
  // }

  return (
    <div>
      <SearchContext.Provider
        value={{
          searchTabs,
          searchTerm,
          searchFilters,
          searchSorter,
          searchActivePage,
          filterDocumentCollection,
          filterDocuments,
          filterDate,
          activeSearchTab,
          getAddTab,
          setQATab,
          setRecentTab,
          getRemoveTab,
          getSetActiveTab,
          getSetTabCount,
          getSetSearchTerm,
          getSetSearchFilters,
          getSetSearchSorter,
          getSetSearchActivePage,
          getSetFilterDocumentCollection,
          getSetFilterDocuments,
          getSetFilterDate,
        }}
      >
        {children}
      </SearchContext.Provider>
    </div>
  )
}
