import { createContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export interface GeneralContextProps {}

export const GeneralContext = createContext({} as GeneralContextProps)

export const GeneralProvider = ({ children }: any) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/signup' &&
      window.location.pathname !== '/reset' &&
      window.location.pathname !== '/verify' &&
      window.location.pathname !== '/unauthorized' &&
      !window.location.pathname.startsWith('/confirm_reset')
    ) {
      localStorage.setItem('lastLocation', String(window.location.pathname + window.location.search))
    }
    // if (location.pathname.startsWith('/api/accounts/confirm/')) {
    //   const n = location.pathname.lastIndexOf('/')
    //   const res = location.pathname.substring(n + 1)
    //   localStorage.setItem('authentication-Token', res)
    //   navigate('/verify')
    // } else if (
    //   authenticationToken === null &&
    //   location.pathname !== '/login' &&
    //   location.pathname !== '/signup' &&
    //   location.pathname !== '/reset' &&
    //   location.pathname !== '/verify' &&
    //   !location.pathname.startsWith('/confirm_reset')
    // ) {
    //   navigate('/login')
    // }
    // eslint-disable-next-line
  }, [navigate])

  return (
    <div>
      <GeneralContext.Provider value={{}}>{children}</GeneralContext.Provider>
    </div>
  )
}
