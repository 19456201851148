import colors from 'assets/theme/base/colors'
import pxToRem from '../functions/pxToRem'

const { primary } = colors

// types
type Types = any

const datepicker: Types = {
  '.datepickr': {
    '.MuiInputBase-root': {
      height: pxToRem(40),
      '.MuiInputAdornment-root': {
        width: pxToRem(8),
      },
    },
  },
  '.datepickr_small': {
    '.MuiInputBase-root': {
      height: pxToRem(30),
      '.MuiInputAdornment-root': {
        width: pxToRem(8),
      },
    },
  },
  '.MuiDayCalendar-weekContainer': {
    '.Mui-selected': {
      backgroundColor: `${primary.main} !important`,
    },
  },
}

export default datepicker
