import { DocumentTocProps } from './TocPanel.type'

export const getDocumentToc = async (documentId?: string): Promise<DocumentTocProps[]> => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/document/toc/${documentId}`, {
    credentials: 'include',
    method: 'GET',
  })

  if (response.status > 399) {
    const resp = response.statusText
    return Promise.reject(resp)
  }

  const result = await response.json()
  if (response.ok) {
    return result
  }
  if (!response.ok) {
    const resp: any = response
    return Promise.reject(String(resp?.errors.map((err: any) => err)))
  }
  return result
}
