import { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// @ts-ignore
// import Cookies from 'js-cookie'

// Functional
import { altGetUserLogin, getUserInfo, getUserLogin, getUserLogout } from './UserContext.service'

// Context
import { ErrorContext } from 'context/ErrorContext'

export interface UserContextProps {
  user?: any
  token: string
  userName?: string
  userLoading: boolean
  userLoader: any
  userStatus?: number
  userError?: string
  userResetLoading?: boolean
  userResetResult?: string
  userRoles?: string[]
  userSSO?: boolean
  logoutUser: (password: string, email: string) => Promise<void>
  loginUser: (password: string, email: string) => Promise<void>
  altToken: string
  altLoginUser: (password: string, email: string) => Promise<void>
  setUserTrigger: any
  adminPanel: boolean
  toggleAdminPanel: () => void
}

export const UserContext = createContext({} as UserContextProps)

export const UserProvider = ({ children }: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [user, setUser] = useState('')
  const [token, setToken] = useState('')
  const [userStatus, setUserStatus] = useState()
  const [userError, setUserError] = useState<string>()
  const [userLoading, setUserLoading] = useState(false)
  const [userName, setUserName] = useState('')
  const [userSSO, setUserSSO] = useState(false)
  const [userLoader, setUserLoader] = useState('loading')
  const [userRoles, setUserRoles] = useState([])
  const [userTrigger, setUserTrigger] = useState()
  const [adminPanel, setAdminPanel] = useState(false)

  // Error handling
  const { setErrorOpen } = useContext(ErrorContext)

  // Widget alt
  const [altToken, setAltToken] = useState('')

  const logoutUser = async () => {
    if (location.pathname.startsWith('/widget/article')) {
      await getUserLogout().then(async () => {
        setToken('')
        localStorage.clear()
        sessionStorage.removeItem('altToken')
        navigate('/widget/login')
      })
    } else {
      await getUserLogout().then(async () => {
        setToken('')
        localStorage.clear()
        sessionStorage.clear()
        navigate('/login')
      })
    }
  }

  const loginUser = async (email: string, password: string) => {
    setUserError(undefined)
    setUserLoading(true)
    await getUserLogin(email, password).then(async (response) => {
      if (response.startsWith('error')) {
        setUserError(response)
      } else {
        setUser(response)
        setToken(response)
        if (response) {
          await getUserInfo().then(async (response) => {
            if (response.status === 1) {
              navigate('/verify')
            }
            if (response.status === 2) {
              const lastLocation = localStorage.getItem('lastLocation')
              navigate(lastLocation || '/')
            }
            setUserName(response.user)
            setUserStatus(response.status)
            setUserRoles(response.roles)
            setUserSSO(response.is_sso_user === 1)
          })
        } else {
          navigate('/login')
        }
      }
    })
    setUserLoading(false)
  }

  const loadGetUser = async () => {
    if (!window.location.pathname.startsWith('/widget/article')) {
      setUserLoading(true)
      await getUserInfo().then((response) => {
        if (!response?.is_authenticated) {
          navigate('/login')
        } else {
          if (window.location.pathname === '/login') {
            window.location.replace('/')
          }
          setUserName(response.user)
          setUserSSO(response.is_sso_user === 1)
          setUserStatus(response.status)
          setUserRoles(response.roles)
        }
      })
      setUserLoading(false)
    }
  }

  // NORMAL ROUTE //
  useEffect(() => {
    if (
      window.location.pathname !== '/signup' &&
      window.location.pathname !== '/reset' &&
      window.location.pathname !== '/unauthorized' &&
      !window.location.pathname.startsWith('/confirm_reset') &&
      window.location.pathname !== '/widget/login'
    ) {
      loadGetUser()
    }
    if (window.location.pathname === '/login') {
      // const cookies = Cookies.get()
      // const tokenAvailable = cookies['XSRF-TOKEN']
      // if (tokenAvailable) {
      //   navigate('/')
      // }
    }
    // eslint-disable-next-line
  }, [userTrigger])

  useEffect(() => {
    if (
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/signup' &&
      window.location.pathname !== '/reset' &&
      window.location.pathname !== '/verify' &&
      !window.location.pathname.startsWith('/confirm_reset')
    ) {
      localStorage.setItem('lastLocation', String(window.location.pathname + window.location.search))
    }
  }, [navigate])

  useEffect(() => {
    setErrorOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    if (userStatus === null) {
      navigate('/verify')
    }
    if (userStatus === 0) {
      navigate('/verify')
    }
    if (userStatus === 1) {
      navigate('/verify#verified')
    }
    if (userStatus === 2 && window.location.pathname === '/verify') {
      // navigate('/')
    }
    // eslint-disable-next-line
  }, [userStatus])

  useEffect(() => {
    // Using string because booleans do not go through in the App.tsx also temporarily using timeout
    setTimeout(() => setUserLoader(userLoading ? 'loading' : 'loaded'), 400)
    // eslint-disable-next-line
  }, [userLoading, userLoader])

  // Widget iFrame //
  const altLoginUser = async (email: string, password: string) => {
    setUserError(undefined)
    setUserLoading(true)
    await altGetUserLogin(email, password).then(async (response) => {
      if (response) {
        if (response === 'error: Authentication failed - identity or password/passcode invalid') {
          setUserError('error')
        } else {
          setAltToken(response?.response.user?.authentication_token)
          sessionStorage.setItem('altToken', response?.response.user?.authentication_token)
          const lastLocation = sessionStorage.getItem('lastLocationWidget')
          navigate(lastLocation || '/widget/login')
        }
      } else {
        navigate('/widget/login')
      }
    })
    setUserLoading(false)
  }

  useEffect(() => {
    const altToken = sessionStorage.getItem('altToken')
    const lastLocationWidget = sessionStorage.getItem('lastLocationWidget')
    if (altToken && lastLocationWidget) {
      navigate(lastLocationWidget)
    }
    // eslint-disable-next-line
  }, [])

  // Admin Panel //
  useEffect(() => {
    const adminToggle: boolean = localStorage.getItem('adminToggle') === 'true' ? true : false
    setAdminPanel(adminToggle)
  }, [])

  useEffect(() => {
    const adminToggle: boolean = adminPanel
    localStorage.setItem('adminToggle', adminToggle === true ? 'true' : '')
  }, [adminPanel])

  const toggleAdminPanel = async () => {
    setAdminPanel(!adminPanel)
  }

  return (
    <div>
      <UserContext.Provider
        value={{
          user,
          token,
          userLoading,
          userLoader,
          userError,
          userStatus,
          userRoles,
          userSSO,
          logoutUser,
          loginUser,
          altLoginUser,
          userName,
          altToken,
          setUserTrigger,
          adminPanel,
          toggleAdminPanel,
        }}
      >
        {children}
      </UserContext.Provider>
    </div>
  )
}
