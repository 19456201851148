import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

// @mui imports
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles/createTheme";
import Tooltip from "@mui/material/Tooltip";

// CX imports
import { CXIconArticle, CXIconFile } from "components/CXIcon/CXIcon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

interface SearchResultBreadcrumbProps {
  document_name: string;
  document_link: string;
  article_title: string;
  article_link: string;
  secondLast_name?: string;
  rest?: ReactElement;
  secondLast?: ReactElement;
}

const truncateString = (string: string) => {
  return string.length > 25 ? string.slice(0, 25 - 1) + ".." : string;
};

export const SearchResultBreadcrumb = (props: SearchResultBreadcrumbProps) => {
  const navigate = useNavigate();
  const breadcrumbTemplate = (
    title: string,
    color: string,
    icon?: string,
    tooltip?: ReactElement | string,
    index?: number,
    link?: string
  ) => {
    return (
      <Tooltip title={tooltip ? tooltip : ""} placement="bottom">
        <MDBox
          py={"4px"}
          px={"8px"}
          sx={({ borders: { borderRadius } }: Theme) => ({
            cursor: link ? "pointer" : "default",
            display: "flex",
            alignItems: "center",
            backgroundColor: color,
            borderRadius: borderRadius.md,
          })}
          onClick={() => (link ? navigate(`/article/${link}`) : null)}
        >
          {icon && index === 0 && <CXIconFile sx={{ mr: "9px" }} />}
          {icon && index === 4 && <CXIconArticle sx={{ mr: "9px" }} />}
          <MDTypography variant="caption1">{title}</MDTypography>
        </MDBox>
      </Tooltip>
    );
  };
  return (
    <MDBox sx={{ display: "inline-flex", alignItems: "center" }}>
      {breadcrumbTemplate(
        props.document_name,
        "grey.100",
        "description",
        "",
        0,
        props.document_link
      )}
      <MDBox px={2} pt={"5px"}>
        <Icon>chevron_right</Icon>
      </MDBox>
      {breadcrumbTemplate("...", "grey.100", undefined, props.rest, 1)}
      <MDBox px={2} pt={"5px"}>
        <Icon>chevron_right</Icon>
      </MDBox>
      {props.secondLast_name &&
        breadcrumbTemplate(
          props.secondLast_name,
          "grey.100",
          undefined,
          props.secondLast,
          2
        )}
      <MDBox px={2} pt={"5px"}>
        <Icon>chevron_right</Icon>
      </MDBox>
      {breadcrumbTemplate(
        truncateString(props.article_title),
        "#ECFDFF",
        "content_paste_search",
        props.article_title,
        4,
        props.article_link
      )}
    </MDBox>
  );
};
