export const getUserLogin = async (email: string, password: string) => {
  const credentials = {
    email: email,
    password: password,
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_API_LOGIN_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
    const result = await response.json()
    if (response.ok) {
      return result.response.csrf_token
    } else {
      return 'error: ' + result.response.errors[0]
    }
  } catch (error) {
    return error
  }
}

export const getUserLogout = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_LOGIN_URL}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const result = await response.json()
    if (response.ok) {
      return result.response.user.authentication_token
    } else {
      return 'error: ' + result.msg
    }
  } catch (error) {
    return error
  }
}

export const getUserInfo = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/accounts/userinfo`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const result = await response.json()
    if (response.ok) {
      return result
    } else {
      return 'error: ' + result.msg
    }
  } catch (error) {
    return error
  }
}

// Widget iframe
export const altGetUserLogin = async (email: string, password: string) => {
  const credentials = {
    email: email,
    password: password,
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_API_LOGIN_URL}/login?include_auth_token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
    const result = await response.json()
    if (response.ok) {
      return result
    } else {
      return 'error: ' + result.response.errors[0]
    }
  } catch (error) {
    return error
  }
}
