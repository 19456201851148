// @mui imports
import { Theme } from "@mui/material/styles/createTheme";

// compendiumX imports
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Types
import ModuleWrapperProps from "./ModuleWrapper.type";

const ModuleWrapper: React.FC<ModuleWrapperProps> = (props) => {
  const { backgroundColor, moduleName, children } = props;
  return (
    <>
      {moduleName && (
        <MDBox mb={4}>
          <MDTypography
            variant="displayXS"
            sx={({ palette: { grey } }: Theme) => ({
              backgroundColor: backgroundColor === "grey" ? grey[200] : "white",
              color: grey[900],
            })}
          >
            {moduleName}
          </MDTypography>
        </MDBox>
      )}
      <MDBox
        sx={({ palette: { grey } }: Theme) => ({
          backgroundColor: backgroundColor === "grey" ? grey[200] : "white",
        })}
      >
        {children}
      </MDBox>
    </>
  );
};

export default ModuleWrapper;
