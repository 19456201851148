// Material Dashboard 2 PRO React TS Base Styles
import colors from 'assets/theme/base/colors'

const { grey } = colors

// types
type Types = any

const switchButton: Types = {
  defaultProps: {
    disableRipple: false,
  },

  styleOverrides: {
    root: {
      width: 36,
      height: 20,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: '#8888FF',
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#33cf4d',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: grey[100],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          backgroundColor: '#DCDCFF',
          opacity: 0.7,
        },
      },
      '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        boxSizing: 'border-box',
        width: 16,
        height: 16,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
      },
    },
  },
}

export default switchButton
